<template>
  <div class="">
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <b-modal
      id="payment-processing-modal-id"
      ref="payment-processing-modal-ref"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="text-center">
        <br />
        <br />
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <br />
        <br />
        <p>Payment Processing</p>
        <br />
        <br />
      </div>
    </b-modal>
    <b-row>
      <b-col md="12" class="mb-1 mt-2 d-flex">
        <h2>Item No - {{ productData.prod_auto_id }}</h2>
        <h2 class="text-primary">
          &nbsp; ({{ getCapital(productData.prod_item_type) }})
        </h2>
        <!-- @click="shareDetails" -->

        <b-button
          v-if="user_type == 'ADMIN'"
          variant="primary"
          size="sm"
          class="ml-2"
          v-b-modal.modal-share-item-details
        >
          <feather-icon icon="Share2Icon" />
        </b-button>
      </b-col>

      <b-col
        md="12"
        sm="12"
        lg="12"
        v-if="productData.prod_item_type == 'sell'"
      >
        <div class="sell-div">
          <b-row>
            <!-- TimeLine of SELL -->
            <b-col md="6" class="mb-5">
              <!-- DRAFT SECTION -->
              <div
                class="draft-div"
                align="center"
                v-if="productData.prod_status_save_type == 'draft'"
              >
                <h3 class="text-center mt-3">Oops, Item is in Draft</h3>
              </div>

              <!-- DEACTIVATED SECTION -->
              <div
                class="draft-div"
                align="center"
                v-if="productData.prod_published == false"
              >
                <h3 class="text-center mt-3">Oops, Your Item Is Deactivated</h3>
              </div>

              <b-card class="submit-div">
                <div class="d-flex mb-2">
                  <feather-icon icon="AlignJustifyIcon" size="18" />
                  <h4 class="ml-2">Status Timeline</h4>
                </div>

                <hr style="margin-bottom: 1rem !important" />

                <!-- SELLER SELL_FLOW-->
                <app-timeline
                  class="seller-section"
                  v-if="user_type == 'SELLER'"
                >
                  <div>
                    <!-- 1) Under Review -->
                    <app-timeline-item
                      variant="success"
                      :class="
                        parseInt(productData.prod_status) >= 1
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item under review</h6>
                      </div>
                      <p>
                        Thank you for submitting your item! Your item is under
                        review with our Revivify Experts.
                      </p>
                    </app-timeline-item>


                    <!-- Chat Module -->
                    <app-timeline-item
                      variant="success"
                      v-if="isChatEnabled"
                    >
                      <div>
                        
                        <section class="msger">
                          <header class="msger-header">
                            <div class="msger-header-title">
                              <i class="fas fa-comment-alt"></i> RevivifyChat
                            </div>
                            <div class="msger-header-options">
                              <span class="btn btn-sm btn-danger">End Chat</span>
                            </div>
                          </header>

                          <main class="msger-chat">

                            <div 
                              v-for="(data, index) in itemChats"
                              :key="index"
                            >

                            <!-- LeftChat -->
                            <div class="msg left-msg" v-if=" data.user.user_email != user_email ">
                              <div class="msg-bubble">
                                <div class="msg-info">
                                  <div class="msg-info-name">{{data.user.user_fname}}</div>
                                  <div class="msg-info-time">12:45</div>
                                </div>

                                <div class="msg-text">
                                  {{ data.ic_message }}
                                </div>
                              </div>
                            </div>

                            <!-- RightChat -->
                            <div class="msg right-msg" v-if=" data.user.user_email == user_email ">
                              <div class="msg-bubble">
                                <div class="msg-info">
                                  <div class="msg-info-name">{{data.user.user_fname}}</div>
                                  <div class="msg-info-time">12:46</div>
                                </div>

                                <div class="msg-text">
                                  {{ data.ic_message }}
                                </div>
                              </div>
                            </div>

                          </div>
                          <div id="endOfChat" class="endOfChat" ref="endOfChat" />


                          </main>

                          <div class="msger-inputarea">
                            <input v-model="chatMessage" type="text" class="msger-input" placeholder="Enter your message...">
                            <button v-on:click="sendMessage()" class="msger-send-btn">Send</button>
                          </div>
                        </section>

                      </div>
                    </app-timeline-item>

                    <!-- 2) Rejected Review -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 2"
                      :class="
                        parseInt(productData.prod_status) >= 2
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Review Rejected</h6>
                      </div>
                      <p>Reason - {{ stl_admin_ur_reason }}</p>
                      <p>Contact support for more details.</p>
                    </app-timeline-item>

                    <!-- 3) Item Approved (Accepted) -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 3"
                      :class="
                        parseInt(productData.prod_status) >= 3
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Approved (Accepted)</h6>
                      </div>
                      <p>
                        Your item is approved. Kindly find the estimated
                        pre-quote of your item below:
                      </p>
                      <p>
                        * Estimated Pre-Quote
                        <feather-icon
                          icon="InfoIcon"
                          v-b-popover.hover.top="
                            'The given pre-quote is an estimate value of your item, pre-quote includes revivify commission as well.'
                          "
                        />
                        :
                        {{
                          productData.prod_amount_currency +
                          stl_admin_est_amount
                        }}
                        <br/>
                        The given pre-quote is an estimate value of your item, pre-quote includes revivify commission as well.
                      </p>

                      <p v-if="stl_admin_ur_reason != ''">
                        Remark: {{ stl_admin_ur_reason }}
                      </p>

                      <p v-if="selectedPickupAddress != null">
                        <b>Select your Pick-up Address below:</b>
                        {{ selectedPickupAddress }}
                      </p>

                      <b-button
                        id="complete-form-toggle-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-lg-seller-address
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                        v-if="parseInt(productData.prod_status) == 3"
                      >
                        Choose Pickup Address
                      </b-button>

                      <b-button
                        v-if="parseInt(productData.prod_status) != 3"
                        id="complete-form-toggle-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-lg-seller-address
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                        @click="
                          () => {
                            address_type = 'update_address';
                          }
                        "
                      >
                        Change/Update Pickup Address
                      </b-button>

                      <div v-if="sell_estimate_amount_rejected">
                        <b-form-input
                          v-if="
                            parseInt(productData.prod_status) != 3
                              ? stl_slr_ea_reason != null
                              : true
                          "
                          v-model="stl_slr_ea_reason"
                          class="mb-1 mt-1"
                          placeholder="Add Reason for Rejecting"
                          :disabled="!(parseInt(productData.prod_status) == 3)"
                        />
                      </div>

                      <!-- Visible on status_code = 3 -->
                      <b-button
                        v-if="parseInt(productData.prod_status) == 3"
                        v-on:click="
                          sellChangeStatus(5, 'estimate_amount_accepted')
                        "
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                      >
                        Accept
                      </b-button>

                      <!-- Visible on status_code = 3 -->
                      <b-button
                        v-if="parseInt(productData.prod_status) == 3"
                        v-on:click="
                          sellChangeStatus(4, 'estimate_amount_rejected')
                        "
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="ml-1"
                      >
                        Reject
                      </b-button>

                      <!-- <b-button
                        v-on:click="downloadPDF()"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                      >
                        Download PDF
                      </b-button> -->
                    </app-timeline-item>

                    <!-- 4) Estimation Amount Rejected -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 4"
                      :class="
                        parseInt(productData.prod_status) >= 4
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Estimation Amount Rejected</h6>
                      </div>
                      <p>Your Reason - {{ stl_admin_ur_reason }}</p>
                    </app-timeline-item>

                    <!-- 5) Item PickedUp -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 5"
                      :class="
                        parseInt(productData.prod_status) >= 5
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Pickup</h6>
                      </div>
                      <p v-if="parseInt(productData.prod_status) == 5">
                        Kindly pack your item, our Revivify Experts will
                        schedule a pickup soon.
                      </p>
                      <p v-if="parseInt(productData.prod_status) == 6">
                        Pickup for your item is scheduled &amp; it will be
                        picked up within 7 working days by our Revivify Pickup
                        Partner from the below mentioned address:
                        <br />
                        {{ selectedPickupAddress }}
                        <br />
                        Also please download the pickup label below and attach
                        it to the item package before handing it over to our
                        Revivify pickup partner.
                      </p>
                      <p v-if="parseInt(productData.prod_status) == 7">
                        Thank your for handing your item to the Revivify Pickup
                        Partner, your item is in transit. We will let you know
                        once it is reached at Revivify Headquarters.
                      </p>
                      <p v-if="parseInt(productData.prod_status) >= 8">
                        Your item have arrived at the Revivify Headquarters
                        &amp; it is under Final Authentication with our Revivify
                        Experts.
                      </p>

                      <b-button
                        v-if="parseInt(productData.prod_status) >= 6"
                        v-on:click="downloadLabel(pickup_lable_link)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                      >
                        Download Pickup Label
                      </b-button>

                      <!-- Visible on status_code = 6 -->
                      <b-button
                        v-if="parseInt(productData.prod_status) == 6"
                        v-on:click="sellChangeStatus(7, 'item_pickedup')"
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                      >
                        Item Picked-Up
                      </b-button>
                    </app-timeline-item>

                    <!-- 8) 2 Step Authentication -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 8"
                      :class="
                        parseInt(productData.prod_status) >= 8
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Final Authentication</h6>
                      </div>
                      <!-- only visible for status_code = 8 -->
                      <div
                        class="show-on-status-8"
                        v-if="parseInt(productData.prod_status) == 8"
                      >
                        <p>Under Inspection</p>
                      </div>
                      <!-- only visible for status_code = 9 -->
                      <div
                        class="only-for-status-9-2-step-rejected"
                        v-if="parseInt(productData.prod_status) == 9"
                      >
                        <p>Rejection Reason: {{ stl_admin_auth_reason }}</p>
                        <p>Contact Support</p>
                      </div>
                    </app-timeline-item>

                    <!-- 10) Final Quotation -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 10"
                      :class="
                        parseInt(productData.prod_status) >= 10
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Final Quotation</h6>
                      </div>

                      <!-- Visible on status_code = 10 -->
                      <div
                        class="sho-status-10"
                        v-if="stl_auth_ar == '2_STEP_AUTH_ACCEPTED'"
                      >
                        <p>
                          Your item has passed the Final Authentication. Kindly
                          find the final quotation of your Item below:
                        </p>
                        <p>
                          1. Item Valuation:
                          {{
                            productData.prod_amount_currency +
                            stl_admin_ship_cost
                          }}<br />
                          2. Your Earning:
                          {{
                            productData.prod_amount_currency +
                            stl_admin_earning_price
                          }}<br />

                          <span v-if="stl_admin_auth_additional_comment != ''">
                            <p>
                              {{ stl_admin_auth_additional_comment }}
                            </p>
                          </span>

                          <span style="font-size: 12px; margin-top: 10px"
                            >*Note : Revivify might add minimal shipping cost to
                            the item valuation at the time of listing.</span
                          >
                          <br />
                          <!-- 3. Product Listing Price:
                          {{
                            productData.prod_amount_currency +
                            stl_admin_list_price
                          }}<br /> -->
                          <span v-if="stl_auth_reason != null"
                            >3. Comment: {{ stl_auth_reason }}</span
                          >
                        </p>

                        <div v-if="sell_final_quote_rejected">
                          <b-form-input
                            v-if="
                              parseInt(productData.prod_status) != 10
                                ? stl_slr_fa_reason != null
                                : true
                            "
                            v-model="stl_slr_fa_reason"
                            class="mb-1"
                            placeholder="Final Quotation Reason - Accept/Reject"
                            :disabled="
                              !(parseInt(productData.prod_status) == 10)
                            "
                          />
                        </div>

                        <!-- Visible on status_code = 10 -->
                        <b-button
                          v-if="parseInt(productData.prod_status) == 10"
                          v-on:click="
                            sellChangeStatus(12, 'final_quote_accepted')
                          "
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                        >
                          Accept
                        </b-button>

                        <!-- Visible on status_code = 10 -->
                        <b-button
                          v-if="parseInt(productData.prod_status) == 10"
                          v-on:click="
                            sellChangeStatus(11, 'final_quote_rejected')
                          "
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="ml-1"
                        >
                          Reject
                        </b-button>
                      </div>

                      <!-- Visible on status_code = 11 -->
                      <div class="show-on-status-11" v-else>
                        <p>
                          You have rejected the quotation, for more contact
                          support.
                        </p>
                      </div>
                    </app-timeline-item>

                    <!-- 12) Approved for listing -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 12"
                      :class="
                        parseInt(productData.prod_status) >= 12
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Approved For Listing</h6>
                      </div>
                      <p>
                        Thank you for accepting the final quotation. Your item
                        will be listed on Revivify.com in next 10-15 working
                        days.
                      </p>
                    </app-timeline-item>

                    <!-- 14) Item Listed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 14"
                      :class="
                        parseInt(productData.prod_status) >= 14
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Listed</h6>
                      </div>
                      <p>
                        Item Listed! Your item is successfully listed on
                        Revivify.com, below is the link for your item.<br /><br />
                        Item Link:
                        <a :href="stl_admin_item_url" target="_blank">{{
                          stl_admin_item_url
                        }}</a>
                      </p>
                      <b-button
                        v-b-modal.modal-share
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                      >
                        Share <feather-icon icon="Share2Icon" />
                      </b-button>
                    </app-timeline-item>

                    <!-- 15) Item Sold -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 15"
                      :class="
                        parseInt(productData.prod_status) >= 15
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Sold</h6>
                      </div>
                      <p>
                        Congratulations! Your item is sold. You will receive
                        your earning in your connected payout account within 3-5
                        business working days.
                      </p>
                    </app-timeline-item>

                    <!-- 16) Payment Initiated -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 16"
                      :class="
                        parseInt(productData.prod_status) >= 16
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Payment Initiated</h6>
                      </div>

                      <p>
                        Reivivfy has initiated the payout of amount
                        {{
                          productData.prod_amount_currency +
                          stl_admin_earning_price
                        }}
                      </p>
                    </app-timeline-item>

                    <!-- 17) Payment Completed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 17"
                      :class="
                        parseInt(productData.prod_status) >= 17
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Payment Completed</h6>
                      </div>
                      <p>Payment of the Item has completed successfully!</p>
                    </app-timeline-item>
                  </div>
                </app-timeline>

                <!-- ADMIN - SELL_FLOW -->
                <app-timeline
                  class="seller-section"
                  v-if="user_type == 'ADMIN'"
                >
                  <div>
                    <!-- 1) Under Review -->
                    <app-timeline-item
                      variant="success"
                      :class="
                        parseInt(productData.prod_status) >= 1
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item under review</h6>
                      </div>

                      <!-- <p>Review item <u>here</u>. ATH</p> -->

                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <b-row>
                            <b-col>
                              <p for="">
                                Original Purchase Price:
                                {{
                                  productData.prod_amount_currency +
                                  productData.prod_amount
                                }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="12" md="12" lg="12">
                          <b-row>
                            <b-col sm="6" md="6" lg="6">
                              <label for=""
                                >Brand:
                                {{ productData.prod_designer }} (%)</label
                              >
                              <b-form-input
                                v-if="
                                  parseInt(productData.prod_status) != 1
                                    ? stl_admin_est_amount != null
                                    : true
                                "
                                :disabled="
                                  parseInt(productData.prod_status) !== 1
                                "
                                type="number"
                                class="mb-1"
                                name="ur_brand"
                                placeholder="Brand %"
                                v-model="cev.brand"
                                @keyup="updateEstimatevalue()"
                              />
                            </b-col>
                            <b-col sm="6" md="6" lg="6">
                              <label for=""
                                >Age:
                                {{ productData.prod_age.item_age_title }}
                                ({{ age_percentage }}%)</label
                              >
                              <b-form-input
                                v-if="
                                  parseInt(productData.prod_status) != 1
                                    ? stl_admin_est_amount != null
                                    : true
                                "
                                :disabled="
                                  parseInt(productData.prod_status) !== 1
                                "
                                type="number"
                                class="mb-1"
                                name="ur_age"
                                placeholder="Age %"
                                v-model="cev.age"
                                @keyup="updateEstimatevalue()"
                              />
                            </b-col>
                            <b-col sm="6" md="6" lg="6">
                              <label for=""
                                >Condition:
                                {{ productData.prod_condition }} (%)</label
                              >
                              <b-form-input
                                v-if="
                                  parseInt(productData.prod_status) != 1
                                    ? stl_admin_est_amount != null
                                    : true
                                "
                                :disabled="
                                  parseInt(productData.prod_status) !== 1
                                "
                                type="number"
                                class="mb-1"
                                name="ur_condition"
                                placeholder="Condition %"
                                v-model="cev.condition"
                                @keyup="updateEstimatevalue()"
                              />
                            </b-col>
                            <b-col sm="6" md="6" lg="6">
                              <label for=""
                                >Current Valuation: Item Value</label
                              >
                              <b-form-input
                                v-if="
                                  parseInt(productData.prod_status) != 1
                                    ? stl_admin_est_amount != null
                                    : true
                                "
                                :disabled="
                                  parseInt(productData.prod_status) !== 1
                                "
                                type="number"
                                class="mb-1"
                                name="ur_opp"
                                placeholder="Item Value"
                                v-model="cev.current_value"
                                @keyup="updateEstimatevalue()"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="timeline_revive">
                            <b-col sm="6" md="6" lg="6">
                              <label for="">Trend: </label>

                              <div class="demo-inline-spacing trend-radio">
                                <b-form-radio
                                  v-model="selected_trend"
                                  :id="'trend_' + index"
                                  :name="'trend_' + index"
                                  :value="index"
                                  v-for="(data, index) in trend_list"
                                  :key="index"
                                  @change="updateTrend(index)"
                                  :disabled="
                                    parseInt(productData.prod_status) !== 1
                                  "
                                >
                                  {{
                                    data.tag_name +
                                    ": " +
                                    data.tag_cat_percent +
                                    "%"
                                  }}
                                </b-form-radio>
                              </div>
                            </b-col>
                            <b-col sm="6" md="6" lg="6">
                              <label for="">Rarity: </label>
                              <div class="demo-inline-spacing trend-radio">
                                <b-form-radio
                                  v-model="selected_rarity"
                                  :id="'rarity_' + index"
                                  :name="'rarity_' + index"
                                  :value="index"
                                  v-for="(data, index) in rarity_list"
                                  :key="index"
                                  @change="updateRarity(index)"
                                  :disabled="
                                    parseInt(productData.prod_status) !== 1
                                  "
                                >
                                  {{
                                    data.tag_name +
                                    ": " +
                                    data.tag_cat_percent +
                                    "%"
                                  }}
                                </b-form-radio>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              sm="12"
                              md="12"
                              lg="12"
                              class="d-flex align-items-center"
                            >
                              <div sm="3" md="3" lg="3">
                                <label for="">Estimated Item Value</label>

                                <b-input-group
                                  :prepend="productData.prod_amount_currency"
                                >
                                  <b-form-input
                                    v-if="
                                      parseInt(productData.prod_status) != 1
                                        ? stl_admin_est_amount != null
                                        : true
                                    "
                                    :disabled="
                                      parseInt(productData.prod_status) !== 1
                                    "
                                    v-model="stl_admin_est_amount"
                                    type="number"
                                    name="stl_admin_estimate_amount"
                                    placeholder="Estimate Amount"
                                  />
                                </b-input-group>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          v-if="sell_ur_rejected"
                          class="mt-1"
                        >
                          <label for=""> Additional Remark </label>
                          <b-form-input
                            v-if="
                              parseInt(productData.prod_status) != 1
                                ? stl_admin_ur_reason != null
                                : true
                            "
                            :disabled="parseInt(productData.prod_status) !== 1"
                            v-model="stl_admin_ur_reason"
                            name="stl_admin_under_review_comment"
                            placeholder="Additonal Remarks (Optional)"
                          />
                        </b-col>
                      </b-row>

                      <div class="mt-2" style="text-align: end">
                        <b-button
                          v-if="parseInt(productData.prod_status) == 1"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(3, 'ur_accepted')"
                        >
                          Accept
                        </b-button>

                        <b-button
                          v-if="parseInt(productData.prod_status) == 1"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="ml-1"
                          v-on:click="sellChangeStatus(2, 'ur_rejected')"
                        >
                          Reject
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 2) Review Rejection -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 2"
                      :class="
                        parseInt(productData.prod_status) >= 2
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Review Rejection</h6>
                      </div>
                      <p>
                        Rejection reason: {{ stl_admin_ur_reason }} <br />
                        Note: Re-Open the item (Go back to previous step)
                      </p>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="sellChangeStatus(1, 're_open')"
                      >
                        Re-Open
                      </b-button>
                    </app-timeline-item>

                    <!-- 3) Waiting for seller response -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 3"
                      :class="
                        parseInt(productData.prod_status) >= 3
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Waiting for seller response</h6>
                      </div>
                    </app-timeline-item>

                    <!-- 4) Reject Quotation -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 4"
                      :class="
                        parseInt(productData.prod_status) >= 4
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Estimated Quotation Rejected</h6>
                      </div>
                      <p>Rejection reason: {{ stl_slr_ea_reason }}</p>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="sellChangeStatus(1, 're_open')"
                      >
                        Re-Open
                      </b-button>
                    </app-timeline-item>


                    <!-- Chat Module -->
                    <app-timeline-item
                      variant="success"
                      v-if="isChatEnabled"
                    >
                      <div>
                        
                        <section class="msger">
                          <header class="msger-header">
                            <div class="msger-header-title">
                              <i class="fas fa-comment-alt"></i> RevivifyChat
                            </div>
                            <div class="msger-header-options">
                              <span class="btn btn-sm btn-danger">End Chat</span>
                            </div>
                          </header>

                          <main class="msger-chat">

                            <div 
                              v-for="(data, index) in itemChats"
                              :key="index"
                            >

                            <!-- LeftChat -->
                            <div class="msg left-msg" v-if=" data.user.user_email != user_email ">
                              <div class="msg-bubble">
                                <div class="msg-info">
                                  <div class="msg-info-name">{{data.user.user_fname}}</div>
                                  <div class="msg-info-time">12:45</div>
                                </div>

                                <div class="msg-text">
                                  {{ data.ic_message }}
                                </div>
                              </div>
                            </div>

                            <!-- RightChat -->
                            <div class="msg right-msg" v-if=" data.user.user_email == user_email ">
                              <div class="msg-bubble">
                                <div class="msg-info">
                                  <div class="msg-info-name">{{data.user.user_fname}}</div>
                                  <div class="msg-info-time">12:46</div>
                                </div>

                                <div class="msg-text">
                                  {{ data.ic_message }}
                                </div>
                              </div>
                            </div>

                          </div>
                          <div id="endOfChat" class="endOfChat" ref="endOfChat" />


                          </main>

                          <div class="msger-inputarea">
                            <input v-model="chatMessage" type="text" class="msger-input" placeholder="Enter your message...">
                            <button v-on:click="sendMessage()" class="msger-send-btn">Send</button>
                          </div>
                        </section>

                      </div>
                    </app-timeline-item>


                    <!-- 5) Item PickUp -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 5"
                      :class="
                        parseInt(productData.prod_status) >= 5
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item PickUp</h6>
                      </div>
                      <p>Schedule pickup from {{ selectedPickupAddress }}</p>

                      <b-form-group
                        v-if="parseInt(productData.prod_status) == 5"
                      >
                        <label class="m-0" for="disclaimer"
                          >*Upload Pickup Label</label
                        >
                        <b-form-file
                          type="file"
                          name="label"
                          accept=".pdf"
                          :id="'upload-label'"
                          placeholder="Upload Pickup Label"
                          v-on:change="uploadPickupLabel($event)"
                        />
                      </b-form-group>

                      <b-button
                        v-if="parseInt(productData.prod_status) == 5"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="sellChangeStatus(6, 'pickup_scheduled')"
                      >
                        Schedule Pickup
                      </b-button>

                      <!-- visible on status = 6 -->
                      <b-button
                        v-if="parseInt(productData.prod_status) == 6"
                        v-on:click="sellChangeStatus(7, 'item_pickedup')"
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                      >
                        Item Picked-Up
                      </b-button>

                      <!-- visible on status = 7 -->
                      <b-button
                        v-if="parseInt(productData.prod_status) == 7"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="sellChangeStatus(8, 'item_received')"
                      >
                        Item Received
                      </b-button>
                    </app-timeline-item>

                    <!-- 8) 2 Step Authentication -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 8"
                      :class="
                        parseInt(productData.prod_status) >= 8
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Final Authentication</h6>
                      </div>

                      <div class="show-on-statu-8">
                        <b-row>
                          <b-col sm="6" md="6" lg="6">
                            <b-row>
                              <b-col sm="4" md="4" lg="4" class="mr-0 pr-0">
                                <b-form-input
                                  :disabled="true"
                                  :value="productData.prod_amount_currency"
                                />
                              </b-col>

                              <b-col sm="8" md="8" lg="8">
                                <b-form-group
                                  v-if="
                                    parseInt(productData.prod_status) != 8
                                      ? stl_admin_ship_cost != null
                                      : true
                                  "
                                  description="Item Valuation"
                                  class="mb-1"
                                >
                                  <b-form-input
                                    :disabled="
                                      !(parseInt(productData.prod_status) == 8)
                                    "
                                    v-model="stl_admin_ship_cost"
                                    type="number"
                                    @keyup="calculateSellerEarning()"
                                    placeholder="Item valuation"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col sm="6" md="6" lg="6">
                            <b-form-group
                              v-if="
                                parseInt(productData.prod_status) != 8
                                  ? stl_admin_earning_price != null
                                  : true
                              "
                              :description="`Item Earning Price - Current Comission: ${this.user_comission}%`"
                              class="mb-1"
                            >
                              <b-form-input
                                :disabled="true"
                                v-model="stl_admin_earning_price"
                                type="number"
                                placeholder="Item Earning Price"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col sm="6" md="6" lg="6">
                            <b-form-group
                              v-if="
                                parseInt(productData.prod_status) != 8
                                  ? stl_admin_earning_price != null
                                  : true
                              "
                              description="Listing Price"
                              class="mb-1"
                            >
                              <b-form-input
                                :disabled="
                                  !(parseInt(productData.prod_status) == 8)
                                "
                                v-model="stl_admin_list_price"
                                type="number"
                                placeholder="Listing Price"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col sm="12" md="12" class="mb-1">
                            <b-button
                              v-if="parseInt(productData.prod_status) == 8"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              size="sm"
                              variant="outline-primary"
                              v-on:click="
                                () => {
                                  sell_2_step_comment = true;
                                }
                              "
                            >
                              Add additional comment
                            </b-button>
                          </b-col>

                          <b-col sm="12" md="12" v-if="sell_2_step_comment">
                            <b-form-input
                              v-if="parseInt(productData.prod_status) == 8"
                              :disabled="
                                !(parseInt(productData.prod_status) == 8)
                              "
                              v-model="stl_admin_auth_additional_comment"
                              type="text"
                              class="mb-1"
                              placeholder="Add additional comment"
                            />
                            <div v-else>
                              <label for=""> Additional Comment </label>
                              <p>
                                {{ stl_admin_auth_additional_comment }}
                              </p>
                            </div>
                          </b-col>

                          <b-col sm="12" md="12" v-if="sell_2_step_reject">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 8
                                  ? stl_admin_auth_reason != null
                                  : true
                              "
                              :disabled="
                                !(parseInt(productData.prod_status) == 8)
                              "
                              v-model="stl_admin_auth_reason"
                              type="text"
                              class="mb-1"
                              placeholder="Add Reason for Rejecting"
                            />
                          </b-col>
                        </b-row>

                        <b-button
                          v-if="parseInt(productData.prod_status) == 8"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(10, '2_step_accept')"
                        >
                          Accept
                        </b-button>

                        <b-button
                          v-if="parseInt(productData.prod_status) == 8"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="ml-1"
                          v-on:click="sellChangeStatus(9, '2_step_reject')"
                        >
                          Reject
                        </b-button>
                      </div>

                      <!-- visible for status = 9 -->
                      <div v-if="parseInt(productData.prod_status) == 9">
                        <p>You Have Rejected the 2 Step Authentication.</p>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(8, 're_open')"
                        >
                          Re-Open
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 10) Final Quotation -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 10"
                      :class="
                        parseInt(productData.prod_status) >= 10
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Final Quotation</h6>
                      </div>

                      <div
                        v-if="stl_fa_ar == 'QUOTATION_ACCEPTED'"
                        class="show-on-status-10"
                      >
                        <p>Final Quotation Reviewed</p>
                      </div>

                      <!-- visible on status = 11 -->
                      <div
                        class="show-on-status-11"
                        v-if="stl_fa_ar == 'QUOTATION_REJECTED'"
                      >
                        <p>
                          Quotation Rejected <br />
                          1. Reason: {{ stl_slr_fa_reason }}
                        </p>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(8, 're_open')"
                        >
                          Re-Open
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 12) Approved For Listing -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 12"
                      :class="
                        parseInt(productData.prod_status) >= 12
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Approved For Listing</h6>
                      </div>

                      <div class="show-on-status-10">
                        <b-button
                          v-if="parseInt(productData.prod_status) == 12"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(13, 'send_to_wp')"
                        >
                          Send To WordPress
                        </b-button>
                      </div>

                      <!-- visible on status = 13 -->
                      <div
                        class="show-on-status-13"
                        v-if="parseInt(productData.prod_status) >= 13"
                      >
                        <b-form-input
                          v-if="
                            parseInt(productData.prod_status) != 13
                              ? stl_admin_item_url != null
                              : true
                          "
                          :disabled="!(parseInt(productData.prod_status) == 13)"
                          v-model="stl_admin_item_url"
                          type="url"
                          class="mb-1"
                          placeholder="Item URL Link"
                        />
                        <b-button
                          v-if="parseInt(productData.prod_status) == 13"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(14, 'item_list')"
                        >
                          Item Listed
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 14) Item Listed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 14"
                      :class="
                        parseInt(productData.prod_status) >= 14
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Listed</h6>
                      </div>

                      <p>Item listed on Revivify.com</p>

                      <b-button
                        v-if="parseInt(productData.prod_status) == 14 && user_type == 'ADMIN' && (admin_email == 'Akash@revivify.com' || admin_email == 'akash@revivify.com' || admin_email == 'akash@aashniandco.com') "
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="sellChangeStatus(15, 'item_sold')"
                      >
                        Item Sold
                      </b-button>
                    </app-timeline-item>

                    <!-- 15) Item Sold -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 15"
                      :class="
                        parseInt(productData.prod_status) >= 15
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Item Sold</h6>
                      </div>
                      <p>Item is sold</p>
                      <br />
                      <!-- <div v-if="productData.creator.user_country == '+91'">
                        <span
                          v-if="productData.creator.user_is_payu_pending == 0"
                        >
                          <b-button
                            v-if="parseInt(productData.prod_status) == 15"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            size="sm"
                            variant="outline-primary"
                            v-on:click="
                              sellChangeStatus(16, 'item_payout_initiated')
                            "
                          >
                            PayU - Payout Initiate of amount
                            {{
                              productData.prod_amount_currency +
                              stl_admin_earning_price
                            }}
                          </b-button>
                        </span>
                        <span v-else
                          >Note: Seller's <b><i>PayU</i></b> Payout details is
                          pending, You cannot initiate payout.<br />Kindly
                          notify the seller to complete the Payout details to
                          accept the payment of Item.
                        </span>
                      </div> -->

                      <div v-if="productData.creator.user_country != ''">
                        <span
                          v-if="productData.creator.user_is_stripe_pending == 0"
                        >
                          <b-button
                            v-if="parseInt(productData.prod_status) == 15"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            size="sm"
                            variant="outline-primary"
                            v-on:click="
                              sellChangeStatus(16, 'item_stripe_initiated')
                            "
                          >
                            Stripe - Payout Initiate of amount
                            {{
                              productData.prod_amount_currency +
                              stl_admin_earning_price
                            }}
                          </b-button>
                        </span>
                        <span v-else>
                          Note: Seller's <b><i>Stipe</i></b> Payout details is
                          pending, You cannot initiate payout.<br />Kindly
                          notify the seller to complete the Payout details to
                          accept the payment of Item.
                        </span>
                      </div>
                    </app-timeline-item>

                    <!-- 16) Payment Initiated -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 16"
                      :class="
                        parseInt(productData.prod_status) >= 16
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Payment Initiated</h6>
                      </div>

                      <p>Reivivfy has initiated the payout</p>

                      <b-button
                        v-if="parseInt(productData.prod_status) == 16"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="
                          sellChangeStatus(17, 'item_payout_completed')
                        "
                      >
                        Payment Completed
                      </b-button>
                    </app-timeline-item>

                    <!-- 17) Payment Completed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 17"
                      :class="
                        parseInt(productData.prod_status) >= 17
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>Payment Completed</h6>
                      </div>
                      <p>
                        Payment of the Item has been processed &amp; completed
                        successfully!
                      </p>
                    </app-timeline-item>
                  </div>
                </app-timeline>
              </b-card>
            </b-col>

            <!-- Image of SELL -->
            <b-col lg="6" md="5" class="carousel">
              <b-card>
                <swiper
                  ref="swiperTop"
                  class="swiper-gallery gallery-top"
                  :options="swiperOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  align="center"
                  style="background: white"
                >
                  <swiper-slide
                    v-for="(data, index) in swiperData"
                    :key="index"
                  >
                    <!-- Swiper Download Image -->
                    <b-button
                      v-if="data"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      style="float: right; margin: 10px"
                      @click="downloadImg(data)"
                    >
                      Download Image
                    </b-button>

                    <b-img
                      v-if="data != 'null'"
                      @click="swiperIndex = index"
                      id="main_slider_img"
                      :src="data"
                      style="width: 100%"
                    />
                  </swiper-slide>

                  <div
                    slot="button-next"
                    class="swiper-button-next swiper-button-white"
                  />
                  <div
                    slot="button-prev"
                    class="swiper-button-prev swiper-button-white"
                  />
                </swiper>

                <swiper
                  ref="swiperThumbs"
                  class="swiper gallery-thumbs"
                  :options="swiperOptionThumbs"
                >
                  <swiper-slide
                    v-for="(data, index) in swiperData"
                    :key="index"
                  >
                    <b-img
                      v-if="data != null"
                      :src="data"
                      fluid
                      style="padding-top: 10px"
                    />
                  </swiper-slide>
                </swiper>
              </b-card>
            </b-col>

            <!-- Other Dtails of SELL -->
            <b-col md="12" v-if="productData.prod_item_type == 'sell'">
              <b-card class="details">
                <div class="d-flex">
                  <h3>Item Details</h3>
                  <!-- <b-button
                    :to="{
                      name: `sell_refurbish`,
                      query: { edit: productData.prod_id },
                    }"
                    @click="
                      $router.push({
                        name: `sell_refurbish`,
                        query: { edit: productData.prod_id },
                      })
                    "
                    variant="primary"
                    class="mx-1"
                    size="sm"
                  >
                    Edit Details
                  </b-button> -->
                </div>

                <hr />

                <!-- Right Col: Table -->
                <b-row cols="2" class="p-1">
                  <b-col lg="6" md="12" sm="12" cols="12" class="d-flex">
                    <span class="text-dark"><b>Name : </b></span>
                    <span>
                      &nbsp;{{
                        productUserData.user_fname +
                        " " +
                        productUserData.user_lname
                      }}</span
                    >
                  </b-col>
                  <b-col lg="6" md="12" sm="12" cols="12" class="d-flex">
                    <span class="text-dark"><b>Email : </b></span>
                    <span> &nbsp;{{ productUserData.user_email }}</span>
                  </b-col>
                  <b-col lg="6" md="12" sm="12" cols="12" class="d-flex">
                    <span class="text-dark"><b>Phone : </b></span>
                    <span>
                      &nbsp;{{
                        productUserData.user_phone_code +
                        "-" +
                        productUserData.user_phone
                      }}</span
                    >
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    v-if="productData.prod_item_country != null"
                  >
                    <span class="d-flex"
                      ><b class="text-dark">Country : </b> &nbsp;{{
                        titleCase(productData.prod_item_country)
                      }}</span
                    >
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    v-if="productData.prod_item_city != null"
                  >
                    <span class="d-flex"
                      ><b class="text-dark">City : </b> &nbsp;{{
                        titleCase(productData.prod_item_city)
                      }}</span
                    >
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_charity != ''"
                  >
                    <span class="text-dark">Charity : </span>
                    <span>&nbsp; {{ productData.prod_charity }} </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_celebrity != ''"
                  >
                    <span class="text-dark">Celebrity : </span>
                    <span>&nbsp; {{ productData.prod_celebrity }} </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_category != null"
                  >
                    <span class="text-dark"><b>Item Category : </b></span>
                    <span> &nbsp;{{ productData.prod_category }}</span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_sub_category != null"
                  >
                    <span class="text-dark"><b>Item Type : </b></span>
                    <span> &nbsp;{{ productData.prod_sub_category }}</span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_designer != null"
                  >
                    <span class="text-dark"><b> Designer : </b></span>
                    <span> &nbsp;{{ productData.prod_designer }} </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_history != null"
                  >
                    <span class="text-dark"><b>Item History : </b></span>
                    <span> &nbsp;{{ productData.prod_history }} </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_material != null"
                  >
                    <span class="text-dark"><b>Material : </b></span>
                    <span> &nbsp;{{ productData.prod_material }} </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_condition != null"
                  >
                    <span class="text-dark">Select Condition : </span>
                    <span>&nbsp;{{ productData.prod_condition }} </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_other_condidition != null"
                  >
                    <span class="text-dark">Other Condition : </span>
                    <span>
                      &nbsp;{{ productData.prod_other_condidition }}
                    </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_indian_western != null"
                  >
                    <span class="text-dark">Indian / Western : </span>
                    <span>&nbsp;{{ productData.prod_indian_western }} </span>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_amount != null"
                  >
                    <span class="text-dark">Original Purchase Price : </span>
                    <span
                      >&nbsp;
                      {{
                        productData.prod_amount_currency +
                        productData.prod_amount
                      }}
                    </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="
                      productData.prod_color != null ||
                      productData.prod_color != ''
                    "
                  >
                    <span class="text-dark">Color : </span>
                    <span>&nbsp; {{ productData.prod_color }} </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="
                      productData.prod_select_western_top != null ||
                      productData.prod_select_western_top != ''
                    "
                  >
                    <span class="text-dark">Size : </span>
                    <span
                      >&nbsp;
                      <strong>{{ productData.prod_select_western_top }}</strong>
                    </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_expected_amount != '0'"
                  >
                    <span class="text-dark">Earning Price : </span>
                    <span
                      >&nbsp;{{
                        productData.prod_amount_currency +
                        productData.prod_expected_amount
                      }}
                    </span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    class="d-flex"
                    v-if="productData.prod_date_of_purchase != ''"
                  >
                    <span class="text-dark">Original Purchase Year : </span>
                    <span>&nbsp; {{ productData.prod_date_of_purchase }}</span>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                    sm="12"
                    cols="12"
                    v-if="
                      productData.prod_receipt != null &&
                      productData.prod_receipt != ''
                    "
                  >
                    <span class="text-dark card-text">Receipt : </span>&nbsp;
                    <a
                      class="download_icon"
                      @click="downloadImg(productData.prod_receipt)"
                      download
                      >Download Receipt
                      <span> <feather-icon icon="DownloadIcon" /></span
                    ></a>
                  </b-col>

                  <b-col
                    lg="12"
                    md="12"
                    sm="12"
                    cols="12"
                    class="lh-30"
                    v-if="productData.prod_long_description != null"
                  >
                    <span class="text-dark card-text">Description : </span>
                    <span>&nbsp;{{ productData.prod_long_description }}</span>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <a href="/">Back to Home</a>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col
        md="12"
        sm="12"
        lg="12"
        v-if="productData.prod_item_type == 'refurbish'"
      >
        <div class="sell-div">
          <b-row>
            <!-- TimeLine of REFURBISH -->
            <b-col md="6" class="mb-5">
              <!-- DRAFT SECTION -->
              <div
                class="draft-div"
                align="center"
                v-if="productData.prod_status_save_type == 'draft'"
              >
                <h3 class="text-center mt-3">Oops, Item is in Draft</h3>
              </div>

              <!-- DEACTIVATED SECTION -->
              <div
                class="draft-div"
                align="center"
                v-if="productData.prod_published == false"
              >
                <h3 class="text-center mt-3">Oops, Your Item Is Deactivated</h3>
              </div>

              <b-card class="submit-div">
                <div class="d-flex mb-2">
                  <feather-icon icon="AlignJustifyIcon" size="18" />
                  <h4 class="ml-2">Status Timeline</h4>
                </div>

                <hr style="margin-bottom: 1rem !important" />

                <!-- Refurbish TimeLine For SELLER -->
                <app-timeline v-if="user_type == 'SELLER'">
                  <div>
                    <!-- 1. Under Review -->
                    <app-timeline-item
                      variant="success"
                      :class="
                        parseInt(productData.prod_status) >= 1
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Item review -
                          {{ timeConverter(rtl_timestamp_array[0]) }}
                        </h6>
                      </div>
                      <p>
                        You submitted your item details and it is being reviewed
                        by our expert.
                      </p>
                    </app-timeline-item>

                    <!-- 2. Review Rejected -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 2"
                      :class="
                        parseInt(productData.prod_status) >= 2
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Review Rejected -
                          {{ timeConverter(rtl_timestamp_array[1]) }}
                        </h6>
                      </div>
                      <p>Item Rejected Reason: {{ ref_admin_ur_comment }}</p>
                      <p>Contact Support</p>
                    </app-timeline-item>

                    <!-- 3. Under Review Approved -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 3"
                      :class="
                        parseInt(productData.prod_status) >= 3
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Item Approved -
                          {{ timeConverter(rtl_timestamp_array[1]) }}
                        </h6>
                      </div>
                      <p>
                        Your Item is approved
                        <!-- <br>Pay Cost: ${{sum(service_amount, ref_admin_ur_ship_cost)}},  -->
                        <br />Base Service Price:
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          convertCurrency(
                            productData.prod_currencies_budget,
                            service_amount
                          )
                        }}, <br />Estimated Refurbish Cost:
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          ref_admin_ur_ship_cost
                        }}
                      </p>
                      <p>Pickup Address: {{ selectedPickupAddress }}</p>

                      <b-button
                        id="complete-form-toggle-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-lg-seller-address
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                        v-if="parseInt(productData.prod_status) == 3"
                      >
                        Choose Pickup Address
                      </b-button>

                      <div v-if="ref_estimate_amount_rejected" class="mt-1">
                        <b-form-input
                          v-if="
                            parseInt(productData.prod_status) != 3
                              ? ref_slr_est_amount_comment != null
                              : true
                          "
                          class="mb-1"
                          v-model="ref_slr_est_amount_comment"
                          placeholder="Add Reason for Rejecting"
                          :disabled="!(parseInt(productData.prod_status) == 3)"
                        />
                      </div>

                      <!-- Visible on status_code = 3 -->
                      <b-button
                        v-on:click="
                          changeRefurbishStatus(5, 'estimate_amount_accepted')
                        "
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-if="parseInt(productData.prod_status) == 3"
                      >
                        Accept
                      </b-button>

                      <!-- Visible on status_code = 3 -->
                      <b-button
                        v-on:click="
                          changeRefurbishStatus(4, 'estimate_amount_rejected')
                        "
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="ml-1"
                        v-if="parseInt(productData.prod_status) == 3"
                      >
                        Reject
                      </b-button>

                      <!-- Visible on status_code = 4 -->
                      <div v-if="parseInt(productData.prod_status) == 4">
                        <p>You have rejected, Please Contact Support</p>
                      </div>

                      <!-- Visible on status_code = 5 -->
                      <div
                        class="show-on-code-5"
                        v-if="parseInt(productData.prod_status) == 5"
                      >
                        <!-- You have accepted the Service &amp; Pickup Cost. -->
                        <p>
                          Thank You for Accepting the pre-quote, kindly make the
                          advance payment to proceed further.
                        </p>

                        <b-button
                          v-if="seller_country != '+91'"
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          @click="getPaymentIntentForProdService"
                          class="mr-1"
                        >
                          Make Advance Payment
                          {{
                            productData.prod_currencies_budget + service_amount
                          }}
                          via Stripe
                        </b-button>

                        <b-button
                          v-else
                          class="mr-1"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          @click="getPayuHashForExtraPay"
                        >
                          Accept &amp; Pay Now
                          {{
                            productData.prod_currencies_budget + service_amount
                          }}
                          via PayU
                        </b-button>

                        <b-card>
                          <form id="service-payment-form">
                            <div id="service-payment-element"></div>
                            <br />
                            <b-button
                              v-if="show_service_payment_form"
                              @click="paymentSubmit"
                              >Submit
                              <button
                                style="display: none"
                                id="submit"
                                ref="paymentSubmitButton"
                              ></button>
                            </b-button>
                            <div id="error-message"></div>
                          </form>
                        </b-card>
                      </div>
                    </app-timeline-item>

                    <!-- 6) Item PickedUp -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 6"
                      :class="
                        parseInt(productData.prod_status) >= 6
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Item Pickup -
                          {{ timeConverter(rtl_timestamp_array[3]) }}
                        </h6>
                      </div>
                      <p>
                        Kindly pack your item for pickup &amp; attach label with
                        below details:
                        <br />Item Number : {{ productData.prod_auto_id }}
                        <br />Name :
                        {{ getUserSingleInfo("name", selectedPickupAddress) }}
                        <br />Contact Number :
                        {{ getUserSingleInfo("phone", selectedPickupAddress) }}
                        <br />Email : {{ user_email }}
                      </p>
                      <p v-if="parseInt(productData.prod_status) == 6">
                        Thank You for making the advance payment, Revivify
                        Expert's will be scheduling a pickup for your item soon.
                      </p>
                      <p v-if="parseInt(productData.prod_status) == 7">
                        Revivify Experts have schedule a Pick-up for your item
                        &amp; it will be Picked-Up in next 7 working days from
                        the below mentioned address:
                        <br />
                        {{ selectedPickupAddress }}
                      </p>
                      <p v-if="parseInt(productData.prod_status) == 8">
                        Thank your for handing over your item to Revivify Pickup
                        Partner, your item is &amp; transit and will reach
                        Revivify Headquarters soon.
                        <br />
                        {{ timeConverter(rtl_timestamp_array[4]) }}
                      </p>
                      <p v-if="parseInt(productData.prod_status) > 8">
                        Your item have arrived at Revivify Headquarters &amp; is
                        under Final Authentication with our Revivify Experts.
                      </p>

                      <!-- Visible on status_code = 7 -->
                      <b-button
                        v-on:click="changeRefurbishStatus(8, 'item_pickedup')"
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-if="parseInt(productData.prod_status) == 7"
                      >
                        Item Picked-Up
                      </b-button>
                    </app-timeline-item>

                    <!-- 9) 2 Step Authentication -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 9"
                      :class="
                        parseInt(productData.prod_status) >= 9
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Final Authentication -
                          {{ timeConverter(rtl_timestamp_array[5]) }}
                        </h6>
                      </div>
                      <!-- only visible for status_code = 9 -->
                      <div
                        class="show-on-status-8"
                        v-if="parseInt(productData.prod_status) == 8"
                      >
                        <p>Under Inspection</p>
                      </div>
                      <!-- only visible for status_code = 10 -->
                      <div
                        class="only-for-status-9-2-step-rejected"
                        v-if="parseInt(productData.prod_status) == 10"
                      >
                        <p>Rejection Reason: {{ ref_admin_auth_comment }}</p>
                        <p>Contact Support</p>
                      </div>
                    </app-timeline-item>

                    <!-- 11) Under Review Approved -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 11"
                      :class="
                        parseInt(productData.prod_status) >= 11
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Item Approved Final Authentication -
                          {{ timeConverter(rtl_timestamp_array[6]) }}
                        </h6>
                      </div>
                      <p>
                        Your Item is approved <br />Total Refurbishment Cost :
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          ref_admin_auth_ref_price
                        }}, <br />Shipping Cost :
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          ref_admin_auth_ship_cost
                        }}, <br />Paid Base Cost :
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          convertCurrency(
                            productData.prod_currencies_budget,
                            service_amount
                          )
                        }}, <br /><br />Payable Amount :
                        {{
                          productData.prod_currencies_budget +
                          " " +
                          sum(ref_admin_auth_ship_cost, ref_extra_pay_amount)
                        }}
                      </p>

                      <div v-if="ref_2_step_auth_rejected">
                        <b-form-input
                          v-if="
                            parseInt(productData.prod_status) != 11
                              ? ref_slr_auth_comment != null
                              : true
                          "
                          class="mb-1"
                          v-model="ref_slr_auth_comment"
                          placeholder="Add Reason for Rejecting"
                          :disabled="!(parseInt(productData.prod_status) == 11)"
                        />
                      </div>

                      <!-- Visible on status_code = 11 -->
                      <!-- <b-button
                          v-on:click="changeRefurbishStatus(13, '2_step_auth_accepted')"
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-if="parseInt(productData.prod_status) ==11"
                        >
                          Accept
                        </b-button> -->

                      <div
                        v-if="
                          parseInt(productData.prod_status) == 11 &&
                          parseInt(ref_extra_pay_amount) > 0
                        "
                        class="mb-1"
                      >
                        <b-button
                          v-if="seller_country != '+91'"
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="mr-1"
                          @click="getPaymentIntentForExtraPay"
                        >
                          Accept &amp; Pay
                          {{
                            productData.prod_currencies_budget +
                            sum(ref_admin_auth_ship_cost, ref_extra_pay_amount)
                          }}
                          via Stripe
                        </b-button>

                        <b-button
                          v-else
                          class="mr-1"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          @click="getPayuHashForExtraPay"
                        >
                          Accept &amp; Pay Now
                          {{
                            productData.prod_currencies_budget + service_amount
                          }}
                          via PayU
                        </b-button>

                        <!-- Visible on status_code = 11 -->
                        <b-button
                          v-on:click="
                            changeRefurbishStatus(12, '2_step_auth_rejected')
                          "
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="ml-1"
                          v-if="parseInt(productData.prod_status) == 11"
                        >
                          Reject
                        </b-button>

                        <b-card>
                          <form id="extra-payment-form">
                            <div id="extra-payment-element"></div>
                            <br />
                            <b-button
                              v-if="show_extra_payment_form"
                              @click="paymentSubmit"
                              >Submit
                              <button
                                style="display: none"
                                id="submit"
                                ref="paymentSubmitButton"
                              ></button>
                            </b-button>
                            <div id="error-message"></div>
                          </form>
                        </b-card>
                      </div>

                      <!-- Visible on status_code = 12 -->
                      <div
                        class="show-on-code-12"
                        v-if="parseInt(productData.prod_status) == 12"
                      >
                        <p>You have rejected, Please Contact Support</p>
                      </div>

                      <!-- Visible on status_code = 13 -->
                      <div
                        class="show-on-code-13"
                        v-if="parseInt(productData.prod_status) == 13"
                      >
                        <p>
                          You have accepted the Service &amp; Pickup Cost -
                          {{ timeConverter(rtl_timestamp_array[7]) }}
                        </p>
                      </div>
                    </app-timeline-item>

                    <!-- 14) Under Refurbishment -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 14"
                      :class="
                        parseInt(productData.prod_status) >= 14
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Under Refurbishment -
                          {{ timeConverter(rtl_timestamp_array[8]) }}
                        </h6>
                      </div>
                      <p>Your item under refurbishment</p>
                    </app-timeline-item>

                    <!-- 15) Refurbishment Completed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 15"
                      :class="
                        parseInt(productData.prod_status) >= 15
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Refurbishment Completed -
                          {{ timeConverter(rtl_timestamp_array[9]) }}
                        </h6>
                      </div>
                      <p>
                        Your item refurbishment completed <br />
                        It will be shipped soon.
                      </p>
                    </app-timeline-item>

                    <!-- 16) Item On the way -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 16"
                      :class="
                        parseInt(productData.prod_status) >= 16
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Item on the way -
                          {{ timeConverter(rtl_timestamp_array[10]) }}
                        </h6>
                      </div>
                      <p>
                        AWB Number: {{ ref_admin_awb }} <br />Estimate Delivery
                        date: {{ ref_admin_delivery_date }} <br />Delivery
                        Partner Name: {{ ref_admin_delivery_name }}
                      </p>
                      <b-button
                        v-on:click="
                          changeRefurbishStatus(17, 'slr_item_received')
                        "
                        v-b-toggle.report-list-with-icon
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-if="parseInt(productData.prod_status) == 16"
                      >
                        Item Received
                      </b-button>
                    </app-timeline-item>

                    <!-- 17) Item Received -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 17"
                      :class="
                        parseInt(productData.prod_status) >= 17
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Congratulations -
                          {{ timeConverter(rtl_timestamp_array[11]) }}
                        </h6>
                      </div>
                      <p>
                        Thank for choosing Revivify, Hope you are satisfied with
                        our service.
                      </p>
                    </app-timeline-item>
                  </div>
                </app-timeline>

                <!-- TimeLine For ADMIN -->
                <app-timeline v-if="user_type == 'ADMIN'">
                  <div>
                    <!-- 1) Under Review -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 1"
                      :class="
                        parseInt(productData.prod_status) >= 1
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Item under review -
                          {{ timeConverter(rtl_timestamp_array[0]) }}
                        </h6>
                      </div>

                      <!-- <p>Review item <u>here</u>.</p> -->

                      <b-row>
                        <b-col sm="6" md="6" lg="6">
                          <b-row>
                            <b-col
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="mr-0 pr-0"
                            >
                              <b-form-input
                                :disabled="true"
                                :value="productData.prod_currencies_budget"
                              />
                            </b-col>
                            <b-col sm="9" md="9" lg="9">
                              <b-form-input
                                v-if="
                                  parseInt(productData.prod_status) != 1
                                    ? ref_admin_ur_ship_cost != null
                                    : true
                                "
                                v-model="ref_admin_ur_ship_cost"
                                class="mb-1"
                                name="ref_admin_ur_ship_cost"
                                placeholder="Estimated Refurbish Cost"
                                :disabled="
                                  !(parseInt(productData.prod_status) == 1)
                                "
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="6" md="6" lg="6" v-if="ref_ur_rejected">
                          <b-form-input
                            v-if="
                              parseInt(productData.prod_status) != 1
                                ? ref_admin_ur_comment != null
                                : true
                            "
                            v-model="ref_admin_ur_comment"
                            class="mb-1"
                            name="ref_admin_ur_comment"
                            placeholder="Add reason for Rejecting"
                            :disabled="
                              !(parseInt(productData.prod_status) == 1)
                            "
                          />
                        </b-col>
                      </b-row>

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="changeRefurbishStatus(3, 'ur_accepted')"
                        v-if="parseInt(productData.prod_status) == 1"
                      >
                        Accept
                      </b-button>

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="ml-1"
                        v-on:click="changeRefurbishStatus(2, 'ur_rejected')"
                        v-if="parseInt(productData.prod_status) == 1"
                      >
                        Reject
                      </b-button>
                    </app-timeline-item>

                    <!-- 2) Review Rejection -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 2"
                      :class="
                        parseInt(productData.prod_status) >= 2
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Review Rejection -
                          {{ timeConverter(rtl_timestamp_array[1]) }}
                        </h6>
                      </div>
                      <p>
                        Rejection: {{ ref_admin_ur_comment }} <br />
                        Note: Re-Open the item (Go back to previous step)
                      </p>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="changeRefurbishStatus(1, 're_open')"
                        v-if="parseInt(productData.prod_status) == 2"
                      >
                        Re-Open
                      </b-button>
                    </app-timeline-item>

                    <!-- 3) Waiting for seller response -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 3"
                      :class="
                        parseInt(productData.prod_status) >= 3
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Waiting for seller approval -
                          {{ timeConverter(rtl_timestamp_array[1]) }}
                        </h6>
                      </div>
                    </app-timeline-item>

                    <!-- 4) Reject Charges (Service & Ship Cost) -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) == 4"
                      :class="
                        parseInt(productData.prod_status) >= 4
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Estimated Quotation Rejected -
                          {{ timeConverter(rtl_timestamp_array[3]) }}
                        </h6>
                      </div>
                      <p>Rejection: {{ ref_slr_est_amount_comment }}</p>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="changeRefurbishStatus(3, 're_open')"
                        v-if="parseInt(productData.prod_status) == 4"
                      >
                        Re-Open
                      </b-button>
                    </app-timeline-item>

                    <!-- 6) Item PickUp -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 6"
                      :class="
                        parseInt(productData.prod_status) >= 6
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Item PickUp -
                          {{ timeConverter(rtl_timestamp_array[3]) }}
                        </h6>
                      </div>
                      <p>Schedule pickup from {{ selectedPickupAddress }}</p>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="
                          changeRefurbishStatus(7, 'pickup_scheduled')
                        "
                        v-if="parseInt(productData.prod_status) == 6"
                      >
                        Pickup Scheduled
                      </b-button>

                      <!-- visible on status = 8 -->
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="
                          changeRefurbishStatus(9, 'admin_item_received')
                        "
                        v-if="parseInt(productData.prod_status) == 8"
                      >
                        Item Received<br />
                        {{ timeConverter(rtl_timestamp_array[4]) }}
                      </b-button>
                    </app-timeline-item>

                    <!-- 9) 2 Step Authentication -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 9"
                      :class="
                        parseInt(productData.prod_status) >= 9
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Final Authentication -
                          {{ timeConverter(rtl_timestamp_array[4]) }}
                        </h6>
                      </div>

                      <div
                        class="show-on-statu-8"
                        v-if="parseInt(productData.prod_status) >= 9"
                      >
                        <b-row>
                          <b-col sm="6" md="6">
                            <b-row>
                              <b-col
                                sm="3"
                                md="3"
                                lg="3"
                                xl="3"
                                class="mr-0 pr-0"
                              >
                                <b-form-input
                                  :disabled="true"
                                  :value="productData.prod_currencies_budget"
                                />
                              </b-col>
                              <b-col sm="9" md="9" lg="9" xl="9">
                                <b-form-input
                                  v-if="
                                    parseInt(productData.prod_status) != 9
                                      ? ref_admin_auth_ref_price != null
                                      : true
                                  "
                                  v-model="ref_admin_auth_ref_price"
                                  type="number"
                                  class="mb-1"
                                  placeholder="Final Refurbish Price"
                                  :disabled="
                                    !(parseInt(productData.prod_status) == 9)
                                  "
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col sm="6" md="6">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 9
                                  ? ref_admin_auth_ship_cost != null
                                  : true
                              "
                              v-model="ref_admin_auth_ship_cost"
                              type="number"
                              class="mb-1"
                              placeholder="Shipping Cost"
                              :disabled="
                                !(parseInt(productData.prod_status) == 9)
                              "
                            />
                          </b-col>
                          <b-col sm="12" md="12" v-if="ref_2_step_reject">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 9
                                  ? ref_admin_auth_comment != null
                                  : true
                              "
                              v-model="ref_admin_auth_comment"
                              type="text"
                              class="mb-1"
                              placeholder="Add Reason for Rejecting"
                              :disabled="
                                !(parseInt(productData.prod_status) == 9)
                              "
                            />
                          </b-col>
                        </b-row>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="
                            changeRefurbishStatus(11, '2_step_accept')
                          "
                          v-if="parseInt(productData.prod_status) == 9"
                        >
                          Accept
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          class="ml-1"
                          v-on:click="
                            changeRefurbishStatus(10, '2_step_reject')
                          "
                          v-if="parseInt(productData.prod_status) == 9"
                        >
                          Reject
                        </b-button>
                      </div>

                      <!-- visible for status = 9 -->
                      <div v-if="parseInt(productData.prod_status) == 10">
                        <p>You Have Rejected The 2 Step Auth.</p>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="changeRefurbishStatus(9, 're_open')"
                        >
                          Re-Open
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 11) Waiting for customer approval -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 11"
                      :class="
                        parseInt(productData.prod_status) >= 11
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          Waiting for customer approval -
                          {{ timeConverter(rtl_timestamp_array[6]) }}
                        </h6>
                      </div>

                      <!-- Visible on status = 12 -->
                      <div
                        class="show-on-code-12"
                        v-if="parseInt(productData.prod_status) == 12"
                      >
                        <p>Rejected : {{ ref_slr_auth_comment }}</p>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="changeRefurbishStatus(9, 're_open')"
                          v-if="parseInt(productData.prod_status) == 12"
                        >
                          Re-Open
                        </b-button>
                      </div>

                      <!-- Visible on status = 13 -->
                      <div
                        class="show-on-code-13"
                        v-if="parseInt(productData.prod_status) == 13"
                      >
                        <p>
                          Payment is done @
                          {{ timeConverter(rtl_timestamp_array[7]) }} - Note:
                          {{ ref_slr_auth_comment }}
                        </p>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="sellChangeStatus(14, 'start_refurbish')"
                          v-if="parseInt(productData.prod_status) == 13"
                        >
                          Start Refurbish
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 14) Under Refurbishment -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 14"
                      :class="
                        parseInt(productData.prod_status) >= 14
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Under Refurbishment -
                          {{ timeConverter(rtl_timestamp_array[8]) }}
                        </h6>
                      </div>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        v-on:click="
                          changeRefurbishStatus(15, 'refurbishment_completed')
                        "
                        v-if="parseInt(productData.prod_status) == 14"
                      >
                        Refurbishment Completed
                      </b-button>
                    </app-timeline-item>

                    <!-- 15) Refurbishment Completed -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 15"
                      :class="
                        parseInt(productData.prod_status) >= 15
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Refurbishment Completed -
                          {{ timeConverter(rtl_timestamp_array[9]) }}
                        </h6>
                      </div>

                      <div class="show-on-statu-8">
                        <b-row>
                          <b-col sm="4" md="4">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 15
                                  ? ref_admin_awb != null
                                  : true
                              "
                              v-model="ref_admin_awb"
                              type="text"
                              class="mb-1"
                              placeholder="AWB Number"
                              :disabled="
                                !(parseInt(productData.prod_status) == 15)
                              "
                            />
                          </b-col>
                          <b-col sm="4" md="4">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 15
                                  ? ref_admin_delivery_date != null
                                  : true
                              "
                              v-model="ref_admin_delivery_date"
                              type="date"
                              class="mb-1"
                              placeholder="Est. Delivery Date"
                              :disabled="
                                !(parseInt(productData.prod_status) == 15)
                              "
                            />
                          </b-col>
                          <b-col sm="4" md="4">
                            <b-form-input
                              v-if="
                                parseInt(productData.prod_status) != 15
                                  ? ref_admin_delivery_name != null
                                  : true
                              "
                              v-model="ref_admin_delivery_name"
                              type="text"
                              class="mb-1"
                              placeholder="Delivery Partner Name"
                              :disabled="
                                !(parseInt(productData.prod_status) == 15)
                              "
                            />
                          </b-col>
                        </b-row>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          v-on:click="changeRefurbishStatus(16, 'item_shipped')"
                          v-if="parseInt(productData.prod_status) == 15"
                        >
                          Item Shipped
                        </b-button>
                      </div>
                    </app-timeline-item>

                    <!-- 16) Item On the way -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 16"
                      :class="
                        parseInt(productData.prod_status) >= 16
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Item Shipped -
                          {{ timeConverter(rtl_timestamp_array[10]) }}
                        </h6>
                      </div>
                      <p>Item Shipped, in transit.</p>
                    </app-timeline-item>

                    <!-- 17) Item Received -->
                    <app-timeline-item
                      variant="success"
                      v-if="parseInt(productData.prod_status) >= 17"
                      :class="
                        parseInt(productData.prod_status) >= 17
                          ? ''
                          : 'greyscale hidden'
                      "
                    >
                      <div class="mb-1 mb-sm-0">
                        <h6>
                          Congratulations -
                          {{ timeConverter(rtl_timestamp_array[11]) }}
                        </h6>
                      </div>
                      <p>Item received by seller.</p>
                    </app-timeline-item>
                  </div>
                </app-timeline>
              </b-card>
            </b-col>

            <!-- ImageSlider of REFURBISH -->
            <b-col lg="6" md="5" class="carousel">
              <b-card>
                <swiper
                  ref="swiperTop"
                  class="swiper-gallery gallery-top"
                  :options="swiperOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  align="center"
                  style="background: white"
                >
                  <swiper-slide
                    v-for="(data, index) in swiperData"
                    :key="index"
                  >
                    <!-- Swiper Download Image -->
                    <b-button
                      v-if="data"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      style="float: right; margin: 10px"
                      @click="downloadImg(data)"
                    >
                      Download Image
                    </b-button>

                    <b-img
                      v-if="data != 'null'"
                      @click="swiperIndex = index"
                      id="main_slider_img"
                      :src="data"
                      style="width: 100%"
                    />
                  </swiper-slide>

                  <div
                    slot="button-next"
                    class="swiper-button-next swiper-button-white"
                  />
                  <div
                    slot="button-prev"
                    class="swiper-button-prev swiper-button-white"
                  />
                </swiper>

                <swiper
                  ref="swiperThumbs"
                  class="swiper gallery-thumbs"
                  :options="swiperOptionThumbs"
                >
                  <swiper-slide
                    v-for="(data, index) in swiperData"
                    :key="index"
                  >
                    <b-img
                      v-if="data != null"
                      :src="data"
                      fluid
                      style="padding-top: 10px"
                    />
                  </swiper-slide>
                </swiper>
              </b-card>
            </b-col>

            <!-- REFURBISH CARD DETAILS -->
            <b-col md="12" v-if="productData.prod_item_type == 'refurbish'">
              <b-card class="details">
                <b-row cols="2" class="p-1">
                  <b-col class="d-flex">
                    <span class="text-dark"><b>Name : </b></span>
                    <span>
                      &nbsp;{{
                        productUserData.user_fname +
                        " " +
                        productUserData.user_lname
                      }}</span
                    >
                  </b-col>
                  <b-col class="d-flex">
                    <span class="text-dark"><b>Email : </b></span>
                    <span> &nbsp;{{ productUserData.user_email }}</span>
                  </b-col>
                  <b-col class="d-flex">
                    <span class="text-dark"><b>Phone : </b></span>
                    <span>
                      &nbsp;{{
                        productUserData.user_phone_code +
                        "-" +
                        productUserData.user_phone
                      }}</span
                    >
                  </b-col>

                  <b-col v-if="productData.prod_item_country != null">
                    <div class="d-flex">
                      <span class="text-dark"><b>Country : </b></span>
                      <span>
                        &nbsp;{{ getCapital(productData.prod_item_country) }}
                      </span>
                    </div>
                  </b-col>
                  <b-col v-if="productData.prod_item_city != null">
                    <div class="d-flex">
                      <span class="text-dark"><b>City : </b></span>
                      <span>
                        &nbsp;{{ getCapital(productData.prod_item_city) }}
                      </span>
                    </div>
                  </b-col>

                  <b-col v-if="productData.prod_item_type != null">
                    <div class="d-flex">
                      <span class="text-dark"> <b>Item Type : </b> </span
                      ><span>
                        &nbsp;{{ getCapital(productData.prod_item_type) }}</span
                      >
                    </div>
                  </b-col>

                  <b-col
                    class="d-flex"
                    v-if="productData.prod_category != null"
                  >
                    <span class="text-dark"><b>Item Category : </b></span>
                    <span> &nbsp;{{ productData.prod_category }}</span>
                  </b-col>

                  <b-col
                    class="d-flex"
                    v-if="productData.prod_sub_category != null"
                  >
                    <span class="text-dark"><b>Item Type : </b></span>
                    <span> &nbsp;{{ productData.prod_sub_category }}</span>
                  </b-col>

                  <b-col v-if="productData.prod_designer != null">
                    <div class="d-flex">
                      <span class="text-dark"> <b>Designer : </b> </span
                      ><span> &nbsp;{{ productData.prod_designer }}</span>
                    </div>
                  </b-col>
                  <b-col v-if="productData.prod_delivery_date != null">
                    <div class="d-flex">
                      <span class="text-dark"> <b>Preferred Date : </b> </span
                      ><span> &nbsp;{{ productData.prod_delivery_date }}</span>
                    </div>
                  </b-col>
                  <b-col v-if="productData.prod_material != null">
                    <div class="d-flex">
                      <span class="text-dark"> <b>Material : </b> </span
                      ><span> &nbsp;{{ productData.prod_material }}</span>
                    </div>
                  </b-col>
                  <b-col v-if="productData.prod_condition != null">
                    <div class="d-flex">
                      <span class="text-dark"> <b>Select Condition : </b> </span
                      ><span> &nbsp;{{ productData.prod_condition }}</span>
                    </div>
                  </b-col>

                  <b-col
                    v-if="productData.product_service_id.service_name != null"
                  >
                    <div class="d-flex">
                      <span class="text-dark"> <b>Service Name :</b> </span
                      ><span>
                        &nbsp;{{
                          productData.product_service_id.service_name
                        }}</span
                      >
                    </div>
                  </b-col>

                  <b-col
                    class="d-flex"
                    v-if="
                      productData.prod_color != null ||
                      productData.prod_color != ''
                    "
                  >
                    <span class="text-dark">Color : </span>
                    <span>&nbsp; {{ productData.prod_color }} </span>
                  </b-col>

                  <b-col
                    class="d-flex"
                    v-if="
                      productData.prod_select_western_top != null ||
                      productData.prod_select_western_top != ''
                    "
                  >
                    <span class="text-dark">Size : </span>
                    <span
                      >&nbsp; {{ productData.prod_select_western_top }}
                    </span>
                  </b-col>

                  <b-col v-if="productData.prod_budget != null">
                    <div class="d-flex">
                      <span class="text-dark">
                        <b>Maximum Budget Amount: </b> </span
                      ><span>
                        &nbsp;{{
                          productData.prod_currencies_budget +
                          " " +
                          productData.prod_budget
                        }}</span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <div v-show="false">
      <form ref="payuForm" action="https://test.payu.in/_payment" method="post">
        <input type="hidden" name="key" :value="payu_option.key" />
        <input type="hidden" name="txnid" :value="payu_option.txnid" />
        <input
          type="hidden"
          name="productinfo"
          :value="payu_option.productinfo"
        />
        <input type="hidden" name="amount" :value="payu_option.amount" />
        <input type="hidden" name="email" :value="payu_option.email" />
        <input type="hidden" name="firstname" :value="payu_option.firstname" />
        <input type="hidden" name="lastname" :value="payu_option.lastname" />
        <input type="hidden" name="surl" :value="payu_option.surl" />
        <input type="hidden" name="furl" :value="payu_option.furl" />
        <input type="hidden" name="phone" :value="payu_option.phone" />
        <input type="hidden" name="hash" :value="payu_option.hash" />
        <input type="submit" value="submit" />
      </form>
    </div>

    <!-- Addresses - Modal Show All Seller's Address -->
    <b-modal
      ref="modal-lg-seller-address-ref"
      id="modal-lg-seller-address"
      centered
      size="md"
      title="Choose - Pickup Address"
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-row>
        <b-col sm="12" md="12" lg="12" v-if="!shippingAddressData.length">
          <div class="centered text-center m-2 p-2">
            <h4>No Address Found</h4>
            <b-button
              variant="primary"
              class="m-1 p-1"
              @click="$router.push('/account-setting')"
            >
              Add Address
            </b-button>
          </div>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="12"
          v-for="(address, index) in shippingAddressData"
          :key="index + '_address'"
        >
          <b-card>
            <b-row>
              <b-col md="12" sm="12" lg="12">
                <h6>
                  Address:
                  {{
                    address.add_address_line_1 +
                    ", " +
                    address.add_address_line_2 +
                    ", " +
                    address.add_address_line_3 +
                    ", Landmark: " +
                    address.add_landmark +
                    ", " +
                    address.add_city +
                    ", " +
                    address.add_state +
                    ", PIN: " +
                    address.add_pin_code +
                    ", " +
                    address.add_country
                  }}
                </h6>
              </b-col>
            </b-row>
            <hr class="mb-1" />
            <b-col md="6" lg="6" sm="12" xl="6">
              <p>Name:<br />{{ address.add_name }}</p>
            </b-col>
            <b-col md="6" lg="6" sm="12" xl="6">
              <p>Phone:<br />{{ address.add_phone }}</p>
            </b-col>
            <b-col md="12">
              <b-button
                class="btn btn-primary"
                v-on:click="chooseAddress(index)"
              >
                Choose Address
              </b-button>
            </b-col>
          </b-card>

          <hr />

          <div class="centered text-center m-2 p-2">
            <h4>Add New Address</h4>
            <b-button
              variant="primary"
              class="m-1 p-1"
              @click="$router.push('/account-setting')"
            >
              Add Address
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <gallery
      :images="swiperDataModal"
      :index="swiperIndex"
      @close="swiperIndex = null"
    ></gallery>

    <b-modal id="modal-item-slider" centered size="md">
      <!-- <swiper
        ref="swiperTop"
        class="swiper-gallery gallery-top"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        align="center"
        style="background: white"
      >
        <swiper-slide v-for="(image, index) in swiperDataModal" :key="index">
          <b-button
            v-if="image"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="float: right; margin: 10px"
            @click="downloadImg(image)"
          >
            Download Image
          </b-button>

          <b-img :src="image" style="width: 80%" />
        </swiper-slide>

        <div
          slot="button-next"
          class="swiper-button-next swiper-button-white"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev swiper-button-white"
        />
      </swiper> -->

      <!-- <div
        class="image"
        v-for="(image, imageIndex) in swiperDataModal"
        :key="imageIndex"
        @click="swiperIndex = imageIndex"
        :style="{
          backgroundImage: 'url(' + image + ')',
          width: '300px',
          height: '200px',
        }"
      ></div> -->

      <!-- <swiper
        :slides-per-view="3"
        :space-between="50"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(image, index) in swiperDataModal" :key="index">
          <b-button
            v-if="image"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="float: right; margin: 10px"
            @click="downloadImg(image)"
          >
            Download Image
          </b-button>

          <b-img :src="image" />
        </swiper-slide>
      </swiper> -->
    </b-modal>

    <b-modal
      centered
      class="model_header"
      id="modal-share-item-details"
      ref="ref-item-share-modal"
      title="Share Item Details "
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="shareItemForm">
        <form ref="form" @submit.stop.prevent="handleItemShareSubmit">
          <b-row>
            <b-col md="12" class="p-1">
              <label> Email </label>
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="admin-email"
                  placeholder="Email"
                  v-model="siEmail"
                  :state="errors.length > 0 ? false : null"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12" class="p-1">
              <label> Message </label>
              <b-form-input
                id="message"
                name="message"
                placeholder="Message"
                v-model="siMessage"
              />
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-share"
      ok-only
      ok-title="Accept"
      modal-class="modal-primary"
      centered
      title="Share Options"
      hide-footer
    >
      <div>
        <b-input-group>
          <b-form-input :value="stl_admin_item_url" />
          <b-input-group-append>
            <b-button @click="copyURL()" variant="outline-primary">
              <feather-icon icon="CopyIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <a
          :href="`https://www.facebook.com/sharer/sharer.php?u=${stl_admin_item_url}`"
          target="_blank"
          class="btn btn-primary share_social_icon"
          >Facebook</a
        >
        <a
          :href="`https://twitter.com/share?url=${stl_admin_item_url}`"
          target="_blank"
          class="btn btn-primary share_social_icon"
          >Twitter</a
        >
        <a
          :href="`whatsapp://send?text=Hey! Checkout my item listed on Revivify ${stl_admin_item_url}`"
          target="_blank"
          class="btn btn-primary share_social_icon"
          data-action="share/whatsapp/share"
          >WhatsApp</a
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroupAppend,
  BFormTextarea,
  BTable,
  BLink,
  BModal,
  BSpinner,
  BForm,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCarousel,
  BCarouselSlide,
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BImg,
  BMedia,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  VBPopover,
  BFormSelect,
  BFormSelectOption,
  BButtonGroup,
  BFormFile,
  BIconNodeMinusFill,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import storageService from "@/apiServices/storageService";
import ProductServices from "@/apiServices/ProductServices";
import AddressServices from "@/apiServices/AddressServices";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import { FILESURL } from "@/config/index";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

//import { Swiper, SwiperSlide } from "swiper/vue";
//import "swiper/css/swiper.css";

import VueGallery from "vue-gallery";

import PaymentServices from "@/apiServices/PaymentServices";
import { loadScript } from "@/libs/helpers";
import { STRIPE_PUB_KEY } from "@/config";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { required, email } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import MasterServices from "@/apiServices/MasterServices";

export default {
  metaInfo: {
    title: "Product Details | Revivify",
    htmlAttrs: {
      lang: "en-US",
    },
  },
  components: {
    BInputGroupAppend,
    BFormTextarea,
    BTable,
    BLink,
    BModal,
    BSpinner,
    BForm,
    BFormRadio,
    BFormRadioGroup,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BImg,
    BMedia,
    BCollapse,
    BFormFile,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VBTooltip,
    VBPopover,
    BCarousel,
    BCarouselSlide,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    Carousel3d,
    Slide,
    Swiper,
    SwiperSlide,
    Loading,
    vSelect,
    BFormSelectOption,

    BFormSelect,
    BButtonGroup,

    ValidationProvider,
    ValidationObserver,

    moment,
    gallery: VueGallery,
    FeatherIcon,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
  },

  /* setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  }, */

  data() {
    return {
      required,

      images: [
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/1600/ffffff/000000",
        "https://dummyimage.com/1280/000000/ffffff",
        "https://dummyimage.com/400/000000/ffffff",
      ],
      index: null,
      swiperIndex: null,

      selected_trend: 0,
      selected_rarity: 0,

      designer_percentage: null,
      condition_percentage: null,

      age_percentage: 0,
      cev: {
        brand: 0,
        age: 0,
        condition: 0,
        current_value: 0,
        trend: 0,
        rarity: 0,
      },

      first_comment: "",
      second_comment: "",
      third_comment: "",
      awb_number: "",
      user_full_name: "",
      user_type: "",
      user_email: "",
      userData: JSON.parse(localStorage.getItem("userInfo")),
      productData: {},
      productUserData: {},
      commentsData: {},
      product_id: 0,
      getComment: "",
      productImages: [],
      courierType: "",
      courierTypeTitle: "",
      other_courier_name: "",
      quotationDetails: {},
      earning_price: "",
      listing_price: "",
      firstCommentMessage: "",
      appriasalCommentMessage: "",
      awbDetails: "",
      showHideQuotationControl: false,
      courierServiceSelected: "",
      swiperData: [],
      swiperDataModal: [],

      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      shippingAddressData: [],

      // REFURBISH DATA CODE BASE
      inspectionRadioStatus: "",
      refurbish_accept_reject_comment: "",
      onShippingPickupStatus: "",
      refurbish_final_quotation_comment: "",
      refurbish_final_quotation: "",
      refurbish_under_inscpetion_rejection: "",
      getAllRefurbishComments: {},
      shippingPickupSelected: "",

      ref_ur_accept_reject: "",
      service_amount: "",
      ref_ui_final_amt: "",
      ref_ui_diff_amt: "",
      ref_ui_amt_type: "",
      ref_ui_type: null,
      show_extra_payment_form: false,
      show_service_payment_form: false,

      inspectionAccepting: "",

      getAllSellComments: {},

      stl_slr_ea_reason: "", // Estimate Amount Reason
      stl_slr_fa_reason: "", // Final Quotation Amount
      stl_admin_est_amount: "",
      stl_admin_ur_reason: "",
      stl_admin_earning_price: "",
      stl_admin_list_price: "",
      stl_admin_ship_cost: "",
      stl_admin_auth_reason: "",
      stl_admin_item_url: "",
      stl_admin_auth_additional_comment: "",

      selectedPickupAddress: null,

      stl_ur_ar: "",
      stl_ea_ar: "",
      stl_auth_ar: "",
      stl_fa_ar: "",

      isLoading: false,
      fullPage: true,
      payu_option: {
        key: null,
        txnid: null,
        productinfo: null,
        amount: null,
        email: null,
        firstname: null,
        lastname: null,
        surl: null,
        furl: null,
        phone: null,
        hash: null,
      },

      itemType: "sell",
      showFullDescription: false,
      showAPI: false,
      moreSizeShowHide: false,

      country: null,

      //Refurbish New Models - Jul 3
      ref_slr_est_amount_comment: "",
      ref_slr_auth_comment: "",
      ref_admin_ur_ship_cost: "",
      ref_admin_ur_comment: "",
      ref_admin_auth_ref_price: "",
      ref_admin_auth_ship_cost: "",
      ref_admin_auth_comment: "",
      ref_admin_awb: "",
      ref_admin_delivery_date: "",
      ref_admin_delivery_name: "",

      ref_admin_ur_ar: "",
      ref_slr_ur_ar: "",
      ref_admin_auth_ar: "",
      ref_slr_auth_ar: "",
      ref_admin_shipped: "",
      ref_extra_pay_amount: "",
      ref_extra_pay_type: "",

      stl_timestamp_array: [],
      rtl_timestamp_array: [],

      siEmail: "",
      siMessage: "",

      sell_ur_rejected: true,
      sell_estimate_amount_rejected: false,
      sell_2_step_reject: false,
      sell_final_quote_rejected: false,
      sell_2_step_comment: false,

      ref_ur_rejected: false,
      ref_estimate_amount_rejected: false,
      ref_2_step_reject: false,
      ref_2_step_auth_rejected: false,

      seller_country: null,

      admin_email: null,
      admin_name: null,

      address_type: null,
      pickup_lable_link: null,
      user_comission: 0,

      trend_list: [],
      rarity_list: [],

      chatMessage: '',
      //1 - User & 2 - Admin
      itemChats: [],
      isChatEnabled: false
    };
  },

  mounted() {
    /* this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$el.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$el.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    }); */
    this.intervalFetchData();
  },
  methods: {

    intervalFetchData() {
      setInterval(() => {
        if(this.isChatEnabled){
          this.getMessageChats();
        }
      }, 2000);
    },

    sendMessage(){
      console.log("chatMessage : ",this.chatMessage);

      if(this.chatMessage == ''){
        this.toast("Message is required", "BoxIcon", "danger");
        return;
      }

      const { id } = this.$route.query;

      const payload = {
        message: this.chatMessage,
        prod_id: id
      }

      this.isLoading = true;

      ProductServices.sendMessage(payload)
        .then((response) => {
          console.log(response);
          if (response.data.status) {
            this.chatMessage = '';
            this.getMessageChats();
            this.isLoading = false;
            this.toast("Message Sent", "BoxIcon", "success");
          } else {
            this.toast("Failed to send message!", "BoxIcon", "danger");
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });

    },

    getMessageChats(){
      const { id } = this.$route.query;
      ProductServices.getItemMessages(id)
        .then((response) => {
          console.log(response);
          if (response.data.status) {
            this.itemChats = response.data.data;
            console.log("getItemMessages : ", response.data.data)
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    copyURL() {
      navigator.clipboard.writeText(this.stl_admin_item_url);
      this.toast("URL Copied!", "CopyIcon", "success");
    },

    sum(one, two) {
      return eval(one + "+" + two);
    },

    downloadImg(img_url) {
      let token = localStorage.getItem("token");
      let imgName = img_url.split("/")[img_url.split("/").length - 1];
      let imgExt = img_url.split(".")[img_url.split(".").length - 1];
      axios({
        url: process.env.VUE_APP_BASEURL + "/product/downloadImage/" + imgName, //your url
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        console.log("response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file." + imgExt); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      console.log({ imgName });
    },

    downloadLabel(img_url) {
      if (img_url == null) {
        this.toast("No such pickup label found", "FileIcon", "danger");
        return;
      }
      let token = localStorage.getItem("token");
      let imgName = img_url.split("/")[img_url.split("/").length - 1];
      let imgExt = img_url.split(".")[img_url.split(".").length - 1];
      axios({
        url: process.env.VUE_APP_BASEURL + "/product/downloadLabel/" + imgName, //your url
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        console.log("response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pickup_label." + imgExt); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      console.log({ imgName });
    },

    getCapital(data) {
      if (data) return data.toUpperCase();
      return null;
    },

    getUserSingleInfo(type, pickUpAddress) {
      let response = "";
      let getAddredd = pickUpAddress;

      console.log("pickUpAddress:", pickUpAddress);

      if (getAddredd != null) {
        if (type == "name") {
          const NameArray = getAddredd.split(",");
          let Name = NameArray[0];

          let responseArr = Name.split(": ");
          response = responseArr[1];
        } else if (type == "phone") {
          const PhoneArray = getAddredd.split(",");
          let Phone = PhoneArray[1];

          let phoneAddArr = Phone.split(": ");
          let PhoneArr = phoneAddArr[1];
          response = PhoneArr.split(" - ")[0];
        } else {
          response = "";
        }
      } else {
        response = "N/A";
      }

      return response;
    },

    saveUpdateUnderRevivify(data) {
      ProductServices.saveUpdateUnderRevivify(data)
        .then((response) => {
          console.log("saveUpdateUnderRevivify: ", response);
          if (response.data.status) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    // Update Seller - Sell Flow

    sellChangeStatus(status_code, message) {
      let sendComment = {};
      let status_message = message;
      let sendCommentStatus = false;

      if (status_message == "ur_accepted") {
        if (parseInt(this.cev.current_value) > 0) {
          let saveUpdate = {
            prod_id: parseInt(this.product_id),
            prod_designer_value: this.cev.brand + "",
            prod_age_value: this.cev.age + "",
            prod_conditions_value: this.cev.condition + "",
            prod_current_vlaue: this.cev.current_value + "",
            prod_trend_value: this.cev.trend + "",
            prod_rarity_value: this.cev.rarity + "",
          };

          ProductServices.saveUpdateUnderRevivify(saveUpdate)
            .then((response) => {
              console.log("saveUpdateUnderRevivify: ", response);
              if (
                response.data.status &&
                parseInt(this.cev.current_value) > 0
              ) {
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        } else {
          this.toast("Current Value Required", "BoxIcon", "danger");
          return;
        }

        // Status = 2
        sendCommentStatus = true;
        if (
          this.stl_admin_est_amount == "" ||
          this.stl_admin_est_amount == null
        ) {
          this.toast("Estimate Amount Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "UNDER_REVIEW_ACCEPTED",
          role: "admin",
          data: {
            stl_ur_est_amount: this.stl_admin_est_amount,
            stl_ur_comment: this.stl_admin_ur_reason,
            stl_ur_accept_reject: "ACCEPTED",
          },
        };
      } else if (status_message == "ur_rejected") {
        // Status = 3

        if (!this.sell_ur_rejected) {
          this.sell_ur_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.stl_admin_est_amount == "" ||
          this.stl_admin_ur_reason == "" ||
          this.stl_admin_est_amount == null ||
          this.stl_admin_ur_reason == null
        ) {
          this.toast("Estimate Amount & Reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "UNDER_REVIEW_REJECTED",
          role: "admin",
          data: {
            stl_ur_est_amount: this.stl_admin_est_amount,
            stl_ur_comment: this.stl_admin_ur_reason,
            stl_ur_accept_reject: "ACCEPTED",
          },
        };
      } else if (status_message == "estimate_amount_rejected") {
        // Status = 4

        if (!this.sell_estimate_amount_rejected) {
          this.sell_estimate_amount_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (this.stl_slr_ea_reason == "" || this.stl_slr_ea_reason == null) {
          this.toast("Reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "ESTIMATE_AMOUNT_REJECTED",
          role: "seller",
          data: {
            stl_ea_comment: this.stl_slr_ea_reason,
            stl_ea_accept_reject: "REJECTED",
            stl_ea_pickup_address: "N/A",
          },
        };
      } else if (status_message == "estimate_amount_accepted") {
        // Status = 5
        sendCommentStatus = true;

        if (this.selectedPickupAddress == null) {
          this.toast("Pickup Address", "BoxIcon", "danger");
          return;
        }

        sendComment = {
          prod_id: this.product_id,
          type: "ESTIMATE_AMOUNT_ACCEPTED",
          role: "seller",
          data: {
            stl_ea_comment: this.stl_slr_ea_reason,
            stl_ea_accept_reject: "ACCEPTED",
            stl_ea_pickup_address: this.selectedPickupAddress,
          },
        };
      } else if (status_message == "pickup_scheduled") {
        // Status = 6

        if (this.pickup_lable_link == null) {
          this.toast("Please choose pickup lable", "FileIcon", "danger");
          return;
        }

        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "PICKUP_SCHEDULED",
          role: "admin",
          data: {},
          pickup_lable_link: this.pickup_lable_link,
        };
      } else if (status_message == "item_pickedup") {
        // Status = 7
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_PICKEDUP",
          role: "seller",
          data: {},
        };
      } else if (status_message == "item_received") {
        // Status = 8
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_RECEIVED",
          role: "admin",
          data: {},
        };
      } else if (status_message == "2_step_reject") {
        // Status = 9

        if (!this.sell_2_step_reject) {
          this.sell_2_step_reject = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.stl_admin_auth_reason == "" ||
          this.stl_admin_auth_reason == null
        ) {
          this.toast("Reason is Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "2_STEP_AUTH_REJECTED",
          role: "admin",
          data: {
            stl_earning_price: this.stl_admin_earning_price,
            stl_list_price: this.stl_admin_list_price,
            stl_ship_cost: 0,
            stl_auth_reason: this.stl_admin_auth_reason,
            stl_auth_accept_reject: "2_STEP_AUTH_REJECTED",
          },
        };
      } else if (status_message == "2_step_accept") {
        // Status = 10

        //this.stl_admin_ship_cost == null ||
        //this.stl_admin_auth_reason == null
        //this.stl_admin_ship_cost == "" ||
        //this.stl_admin_auth_reason == "" ||

        sendCommentStatus = true;
        if (
          this.stl_admin_earning_price == "" ||
          this.stl_admin_list_price == "" ||
          this.stl_admin_earning_price == null ||
          this.stl_admin_list_price == null
        ) {
          this.toast(
            "Earning Pice & List Price are required",
            "BoxIcon",
            "danger"
          );
          return;
        }

        let seller_earning = eval(this.stl_admin_ship_cost + "*" + "0.65");

        sendComment = {
          prod_id: this.product_id,
          type: "2_STEP_AUTH_ACCEPTED",
          role: "admin",
          data: {
            stl_earning_price: this.stl_admin_earning_price + "",
            stl_list_price: this.stl_admin_list_price,
            stl_ship_cost: this.stl_admin_ship_cost, //valuation price
            stl_auth_reason: this.stl_admin_auth_reason,
            stl_auth_add_comment: this.stl_admin_auth_additional_comment,
            stl_auth_accept_reject: "2_STEP_AUTH_ACCEPTED",
          },
        };
      } else if (status_message == "final_quote_rejected") {
        // Status = 11

        if (!this.sell_final_quote_rejected) {
          this.sell_final_quote_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (this.stl_slr_fa_reason == "" || this.stl_slr_fa_reason == null) {
          this.toast("Reason is Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "QUOTATION_REJECTED",
          role: "seller",
          data: {
            stl_slr_fa_reason: this.stl_slr_fa_reason,
            stl_slr_fa_accept_reject: "QUOTATION_REJECTED",
          },
        };
      } else if (status_message == "final_quote_accepted") {
        // Status = 12
        sendCommentStatus = true;

        sendComment = {
          prod_id: this.product_id,
          type: "QUOTATION_ACCEPTED",
          role: "seller",
          data: {
            stl_slr_fa_reason: this.stl_slr_fa_reason,
            stl_slr_fa_accept_reject: "QUOTATION_ACCEPTED",
          },
        };
      } else if (status_message == "send_to_wp") {
        // Status = 13
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "SEND_TO_WORDPRESS",
          role: "admin",
          data: {},
        };
      } else if (status_message == "item_list") {
        // Status = 14
        sendCommentStatus = true;
        if (this.stl_admin_item_url == "") {
          this.toast("Item URL is Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_LISTED",
          role: "admin",
          data: {
            stl_item_url: this.stl_admin_item_url,
          },
        };
      } else if (status_message == "item_sold") {
        // Status = 15
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_SOLD",
          role: "admin",
          data: {},
        };
      } else if (
        status_message == "item_payout_initiated" ||
        status_message == "item_stripe_initiated"
      ) {
        // Status = 16
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "PAYOUT_INITIATED",
          role: "admin",
          data: {},
        };
      } else if (status_message == "item_payout_completed") {
        // Status = 17
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "PAYOUT_COMPLETED",
          role: "admin",
          data: {},
        };
      } else if (status_message == "re_open") {
        let reOpenData = {};

        if (status_code == 1) {
          sendCommentStatus = true;
          reOpenData = {
            stl_ur_est_amount: null,
            stl_ur_comment: null,
            stl_ur_accept_reject: null,
            stl_ea_comment: null,
            stl_ea_accept_reject: null,
            stl_ea_pickup_address: null,
            stl_earning_price: null,
            stl_list_price: null,
            stl_ship_cost: null,
            stl_auth_reason: null,
            stl_auth_accept_reject: null,
            stl_slr_fa_reason: null,
            stl_slr_fa_accept_reject: null,
            stl_item_url: null,
          };
        } else if (status_code == 3) {
          sendCommentStatus = true;
          reOpenData = {
            stl_ea_comment: null,
            stl_ea_accept_reject: null,
            stl_ea_pickup_address: null,
            stl_earning_price: null,
            stl_list_price: null,
            stl_ship_cost: null,
            stl_auth_reason: null,
            stl_auth_accept_reject: null,
            stl_slr_fa_reason: null,
            stl_slr_fa_accept_reject: null,
            stl_item_url: null,
          };
        } else if (status_code == 8) {
          sendCommentStatus = true;
          reOpenData = {
            stl_earning_price: null,
            stl_list_price: null,
            stl_ship_cost: null,
            stl_auth_reason: null,
            stl_auth_accept_reject: null,
            stl_slr_fa_reason: null,
            stl_slr_fa_accept_reject: null,
          };
        } else {
          sendCommentStatus = false;
          reOpenData = {};
        }

        sendComment = {
          prod_id: this.product_id,
          type: "RE_OPEN",
          role: "admin",
          data: reOpenData,
        };
      }

      let sendStatus = {
        prod_id: this.product_id,
        role: sendComment.role,
        status: status_code.toString(), //1, 2, 3, 4, etc
        status_message: message, //ur_review, etc
      };

      this.isLoading = true;

      let allSend = {
        sendStatus,
        sendCommentStatus,
        sendComment,
      };

      console.log("allSend:", allSend);
      //return;

      ProductServices.updateStatus(sendStatus)
        .then((response) => {
          console.log(response);
          if (response.data.status) {
            this.isLoading = false;
            this.toast("Status Updated!", "BoxIcon", "success");

            if (sendCommentStatus) {
              this.updateSellComment(sendComment);
            } else {
              if (sendComment.type == "PICKUP_SCHEDULED") {
                this.updatePickupLabel(sendComment);
              }
              this.loadItemDetails();
            }
            this.getSellComment();
          } else {
            this.toast("Failed to update status!", "BoxIcon", "danger");
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updatePickupLabel(data) {
      ProductServices.updatePickupLabel(data)
        .then((response) => {
          console.log("updatePickupLabel: ", response);
          if (response.data.status) {
            this.toast("Successfully Uploaded!", "FileIcon", "danger");
          } else {
            this.toast("Failed to upload pickup label!", "FileIcon", "danger");
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updateShippingStatus(status_code, message) {
      // Update Shipping Status from ADMIN Side //

      const sendStatus = {
        prod_id: this.product_id,
        status: status_code.toString(),
        status_message: message,
      };

      /* console.log("updateShippingStatus: ", sendStatus);
      return false; */

      ProductServices.updateShippingStatus(sendStatus)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Status Updated!",
                icon: "BoxIcon",
                variant: "success",
              },
            });

            this.addProductComment(
              this.product_id,
              message,
              status_code.toString()
            );

            this.loadItemDetails();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "BoxIcon",
                variant: "error",
              },
            });
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    addProductComment(pid, comment, status) {
      const profileData = storageService.UserData.getUserProfile();
      let userInfo = JSON.parse(profileData);

      const senData = {
        prod_id: pid,
        comment: comment,
        status_code: status,
        email: userInfo.user_email,
      };

      ProductServices.addProductComment(senData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    loadUserAction() {
      const profileData = storageService.UserData.getUserProfile();
      let user = JSON.parse(profileData);
      this.user_full_name = user.user_firstname;
      this.user_email = user.user_email;
      if (user.user_role_id == 1) {
        this.user_type = "SELLER";
      } else {
        this.user_type = "ADMIN";
        this.admin_email = user.user_email;
        this.admin_name = user.user_firstname + " " + user.user_lastname;
      }
      console.log(user);
    },

    loadItemDetails() {
      const { id } = this.$route.query;
      //console.log("ID: ", id);
      ProductServices.getSingleProductDetails(id)
        .then((response) => {
          this.productData = response.data.data;
          console.log("PD: ", this.productData);

          this.cev.age = this.productData.prod_age.item_age_value
            ? this.productData.prod_age.item_age_value
            : 0;

          this.age_percentage = this.productData.prod_age.item_age_value
            ? this.productData.prod_age.item_age_value
            : 0;

          if (this.productData.prod_designer_value != null) {
            this.cev.brand = this.productData.prod_designer_value;
          }
          if (this.productData.prod_age_value != null) {
            this.cev.age = this.productData.prod_age_value;
          }
          if (this.productData.prod_conditions_value != null) {
            this.cev.condition = this.productData.prod_conditions_value;
          }
          if (this.productData.prod_current_vlaue != null) {
            this.cev.current_value = this.productData.prod_current_vlaue;
          }
          if (this.productData.prod_trend_value != null) {
            this.cev.trend = this.productData.prod_trend_value;
            //this.selected_trend = this.productData.prod_trend_value;
          }
          if (this.productData.prod_rarity_value != null) {
            this.cev.rarity = this.productData.prod_rarity_value;
            //this.selected_rarity = this.productData.prod_rarity_value;
          }

          this.getSingleTagDetails("designer", this.productData.prod_designer);
          this.getSingleTagDetails(
            "condition",
            this.productData.prod_condition
          );

          this.productUserData = response.data.data.creator;
          this.product_id = parseInt(response.data.data.prod_id);
          this.productImages = response.data.data.prod_item_images;
          this.pickup_lable_link = response.data.data.prod_short_description;
          const revf_shipping = response.data.data.prod_shipping;
          const prod_type = response.data.data.prod_item_type;
          const prod_customisation = response.data.data.prod_customisation;

          let front_image = response.data.data.prod_front_image;
          let back_image = response.data.data.prod_back_image;
          let side_image = response.data.data.prod_side_image;
          let label_image = response.data.data.prod_label_image;
          let defect_image = response.data.data.prod_defect_image;

          this.user_comission = this.productData.creator.user_comission;

          if (front_image != "") {
            this.swiperData.push(front_image);
            this.swiperDataModal.push(front_image);
          }
          if (back_image != "") {
            this.swiperData.push(back_image);
            this.swiperDataModal.push(back_image);
          }
          if (side_image != "") {
            this.swiperData.push(side_image);
            this.swiperDataModal.push(side_image);
          }
          if (label_image != "") {
            this.swiperData.push(label_image);
            this.swiperDataModal.push(label_image);
          }
          if (defect_image != "") {
            this.swiperData.push(defect_image);
            this.swiperDataModal.push(defect_image);
          }

          console.log("IMAGES", this.swiperData);

          this.getSellerAddress(response.data.data.creatorId);

          if (prod_type == "sell") {
            this.getSellComment();
          } else {
            this.service_amount =
              response.data.data.product_service_id.service_base_price;
            this.getRefurbishComment();
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getMessageFromSatatus(status_code) {
      let typeSelectedNew = this.commentsData.filter((x) => {
        return x.psc_status_code == status_code;
      });

      return typeSelectedNew.length > 0 ? typeSelectedNew[0] : "";
    },

    onChangeCourierService(event) {
      console.log(event);
      if (event == "OTHER") {
        this.courierType = event;
        this.courierTypeTitle = this.other_courier_name;
      } else {
        this.courierType = event;
        this.courierTypeTitle = event;
      }
    },

    getSellerAddress(user_id) {
      AddressServices.loadAddress(user_id)
        .then((response) => {
          this.shippingAddressData = response.data.data;
          //console.log("GET SELLER'S ADDRESS: ", this.shippingAddressData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updateSellComment(sendData) {
      this.isLoading = true;

      ProductServices.updateSellComment(sendData)
        .then((response) => {
          if (response.data.status) {
            this.loadItemDetails();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getSellComment() {
      let sendData = {
        prod_id: this.product_id,
      };

      ProductServices.listAllSellItemComments(sendData)
        .then((response) => {
          if (response.data.status) {
            let commentsData = response.data.data;
            this.getAllSellComments = response.data.data;
            console.log("ALL STL DATA: ", this.getAllSellComments);

            if (commentsData.stl_ur_comment != "") {
              this.stl_admin_ur_reason = commentsData.stl_ur_comment;
            }

            if (commentsData.stl_ur_est_amount != "") {
              this.stl_admin_est_amount = commentsData.stl_ur_est_amount;
            }

            if (commentsData.stl_ur_accept_reject != "") {
              this.stl_ur_ar = commentsData.stl_ur_accept_reject;
            }

            if (commentsData.stl_slr_fa_reason != "") {
              this.stl_slr_fa_reason = commentsData.stl_slr_fa_reason;
            }

            if (commentsData.stl_slr_fa_accept_reject != "") {
              this.stl_fa_ar = commentsData.stl_slr_fa_accept_reject;
            }

            if (commentsData.stl_ea_pickup_address != "") {
              this.selectedPickupAddress = commentsData.stl_ea_pickup_address;
            }

            if (commentsData.stl_ea_comment != "") {
              this.stl_slr_ea_reason = commentsData.stl_ea_comment;
            }

            if (commentsData.stl_ea_accept_reject != "") {
              this.stl_ea_ar = commentsData.stl_ea_accept_reject;
            }

            if (commentsData.stl_auth_accept_reject != "") {
              this.stl_auth_ar = commentsData.stl_auth_accept_reject;
            }

            if (commentsData.stl_auth_reason != "") {
              this.stl_admin_auth_reason = commentsData.stl_auth_reason;
            }
            if (commentsData.stl_earning_price != "") {
              this.stl_admin_earning_price = commentsData.stl_earning_price;
            }
            if (commentsData.stl_list_price != "") {
              this.stl_admin_list_price = commentsData.stl_list_price;
            }
            if (commentsData.stl_ship_cost != "") {
              this.stl_admin_ship_cost = commentsData.stl_ship_cost;
            }

            if (commentsData.stl_auth_add_comment != "") {
              this.sell_2_step_comment = true;
              this.stl_admin_auth_additional_comment =
                commentsData.stl_auth_add_comment;
            }

            if (commentsData.stl_item_url != "") {
              this.stl_admin_item_url = commentsData.stl_item_url;
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    // REFURBISH CODE BASE

    onInspectionAR(data) {
      this.inspectionRadioStatus = data;
    },

    onShippingPickup(data) {
      this.onShippingPickupStatus = data;
    },

    changeRefurbishStatus(status_code, message) {
      let sendComment = {};
      let status_message = message;
      let sendCommentStatus = false;

      if (status_message == "ur_accepted") {
        sendCommentStatus = true;
        if (
          this.ref_admin_ur_ship_cost == "" ||
          this.ref_admin_ur_ship_cost == null
        ) {
          this.toast("Estimated Refurbish Cost Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "UNDER_REVIEW_ACCEPTED",
          data: {
            ref_ur_comment: this.ref_admin_ur_comment,
            ref_ur_ship_cost: this.ref_admin_ur_ship_cost,
            ref_ur_accept_reject: "ACCEPTED",
          },
        };
      } else if (status_message == "ur_rejected") {
        if (!this.ref_ur_rejected) {
          this.ref_ur_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.ref_admin_ur_comment == "" ||
          this.ref_admin_ur_comment == null
        ) {
          this.toast("Rejection Reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "UNDER_REVIEW_REJECTED",
          data: {
            ref_ur_comment: this.ref_admin_ur_comment,
            ref_ur_ship_cost: this.ref_admin_ur_ship_cost,
            ref_ur_accept_reject: "REJECTED",
          },
        };
      } else if (status_message == "estimate_amount_accepted") {
        sendCommentStatus = true;
        if (
          this.selectedPickupAddress == "" ||
          this.selectedPickupAddress == null
        ) {
          this.toast("Pickup Address Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "ESTIMATE_AMOUNT_ACCEPTED",
          data: {
            ref_slr_est_pickup_address: this.selectedPickupAddress,
            ref_slr_est_comment: this.ref_slr_est_amount_comment,
            ref_slr_est_accept_reject: "ACCEPTED",
          },
        };
      } else if (status_message == "estimate_amount_rejected") {
        if (!this.ref_estimate_amount_rejected) {
          this.ref_estimate_amount_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.ref_slr_est_amount_comment == "" ||
          this.ref_slr_est_amount_comment == null
        ) {
          this.toast("Rejection Reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "ESTIMATE_AMOUNT_REJECTED",
          data: {
            ref_slr_est_pickup_address: this.selectedPickupAddress,
            ref_slr_est_comment: this.ref_slr_est_amount_comment,
            ref_slr_est_accept_reject: "REJECTED",
          },
        };
      } else if (status_message == "pickup_scheduled") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "PICKUP_SCHEDULED",
          data: {},
        };
      } else if (status_message == "item_pickedup") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_PICKEDUP",
          data: {},
        };
      } else if (status_message == "admin_item_received") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ADMIN_ITEM_RECEIVED",
          data: {},
        };
      } else if (status_message == "2_step_accept") {
        sendCommentStatus = true;

        if (
          this.ref_admin_auth_ref_price == "" ||
          this.ref_admin_auth_ship_cost == "" ||
          this.ref_admin_auth_ref_price == null ||
          this.ref_admin_auth_ship_cost == null
        ) {
          this.toast(
            "Refurbish Price & Ship Cost Required",
            "BoxIcon",
            "danger"
          );
          return;
        }

        let newFinalAmount = 0;
        let newFinalAmountType = "";
        if (
          parseInt(this.service_amount) ==
          parseInt(this.ref_admin_auth_ref_price)
        ) {
          // NO_NEED_TO_PAY
          newFinalAmountType = "EQUAL";
          newFinalAmount =
            parseInt(this.service_amount) -
            parseInt(this.ref_admin_auth_ref_price);
        } else if (
          parseInt(this.service_amount) <
          parseInt(this.ref_admin_auth_ref_price)
        ) {
          // NEED TO PAY MORE
          newFinalAmountType = "PAY_MORE";
          newFinalAmount =
            parseInt(this.ref_admin_auth_ref_price) -
            parseInt(this.service_amount);
        } else if (
          parseInt(this.service_amount) >
          parseInt(this.ref_admin_auth_ref_price)
        ) {
          // REVIVIFY_WILL_REFUND
          newFinalAmountType = "REFUND";
          newFinalAmount =
            parseInt(this.service_amount) -
            parseInt(this.ref_admin_auth_ref_price);
        }

        sendComment = {
          prod_id: this.product_id,
          type: "ADMIN_AUTH_ACCEPTED",
          data: {
            ref_admin_auth_ref_price: this.ref_admin_auth_ref_price,
            ref_admin_auth_ship_cost: this.ref_admin_auth_ship_cost,
            ref_admin_auth_comment: this.ref_admin_auth_comment,
            ref_admin_auth_accept_reject: "ACCEPTED",
            ref_admin_auth_pay_amount: newFinalAmount.toString(),
            ref_admin_auth_pay_type: newFinalAmountType,
          },
        };
      } else if (status_message == "2_step_reject") {
        if (!this.ref_2_step_reject) {
          this.ref_2_step_reject = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.ref_admin_auth_comment == "" ||
          this.ref_admin_auth_comment == null
        ) {
          this.toast("Rejection Reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "ADMIN_AUTH_REJECTED",
          data: {
            ref_admin_auth_ref_price: this.ref_admin_auth_ref_price,
            ref_admin_auth_ship_cost: this.ref_admin_auth_ship_cost,
            ref_admin_auth_comment: this.ref_admin_auth_comment,
            ref_admin_auth_accept_reject: "REJECTED",
            ref_admin_auth_pay_amount: "N/A",
            ref_admin_auth_pay_type: "N/A",
          },
        };
      } else if (status_message == "2_step_auth_accepted") {
        sendCommentStatus = true;
        sendComment = {
          prod_id: this.product_id,
          type: "SLR_AUTH_ACCEPTED",
          data: {
            ref_slr_auth_comment: this.ref_slr_auth_comment,
            ref_slr_auth_accept_reject: "ACCEPTED",
          },
        };
      } else if (status_message == "2_step_auth_rejected") {
        if (!this.ref_2_step_auth_rejected) {
          this.ref_2_step_auth_rejected = true;
          return;
        }

        sendCommentStatus = true;
        if (
          this.ref_slr_auth_comment == "" ||
          this.ref_slr_auth_comment == null
        ) {
          this.toast("Rejection reason Required", "BoxIcon", "danger");
          return;
        }
        sendComment = {
          prod_id: this.product_id,
          type: "SLR_AUTH_REJECTED",
          data: {
            ref_slr_auth_comment: this.ref_slr_auth_comment,
            ref_slr_auth_accept_reject: "REJECTED",
          },
        };
      } else if (status_message == "start_refurbish") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "START_REFURBISH",
          data: {},
        };
      } else if (status_message == "refurbishment_completed") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "START_REFURBISH",
          data: {},
        };
      } else if (status_message == "item_shipped") {
        sendCommentStatus = true;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_SHIPPED",
          data: {
            ref_admin_awb: this.ref_admin_awb,
            ref_admin_delivery_date: this.ref_admin_delivery_date,
            ref_admin_delivery_name: this.ref_admin_delivery_name,
            ref_admin_shipped: "SHIPPED",
          },
        };
      } else if (status_message == "item_received") {
        sendCommentStatus = false;
        sendComment = {
          prod_id: this.product_id,
          type: "ITEM_RECEIVED",
          data: {},
        };
      } else if (status_message == "re_open") {
        let reOpenData = {};

        if (status_code == 1) {
          sendCommentStatus = true;
          reOpenData = {
            ref_ur_comment: null,
            ref_ur_ship_cost: null,
            ref_ur_accept_reject: null,
            ref_slr_est_pickup_address: null,
            ref_slr_est_comment: null,
            ref_slr_est_accept_reject: null,
          };
        } else if (status_code == 3) {
          sendCommentStatus = true;
          reOpenData = {
            ref_ur_comment: null,
            ref_ur_ship_cost: null,
            ref_ur_accept_reject: null,
            ref_slr_est_pickup_address: null,
            ref_slr_est_comment: null,
            ref_slr_est_accept_reject: null,
          };
        } else if (status_code == 9) {
          sendCommentStatus = true;
          reOpenData = {
            stl_earning_price: null,
            stl_list_price: null,
            stl_ship_cost: null,
            stl_auth_reason: null,
            stl_auth_accept_reject: null,
            stl_slr_fa_reason: null,
            stl_slr_fa_accept_reject: null,
            ref_admin_auth_ref_price: null,
            ref_admin_auth_ship_cost: null,
            ref_admin_auth_comment: null,
            ref_admin_auth_accept_reject: null,
            ref_admin_auth_comment: null,
            ref_admin_auth_accept_reject: null,
            ref_admin_auth_pay_amount: null,
            ref_admin_auth_pay_type: null,
          };
        } else {
          sendCommentStatus = false;
          reOpenData = {};
        }

        sendComment = {
          prod_id: this.product_id,
          type: "RE_OPEN",
          data: reOpenData,
        };
      }

      let sendAll = {
        status_message,
        status_code,
        sendComment,
      };
      console.log(sendAll);
      //return;

      const sendStatus = {
        prod_id: this.product_id,
        status: status_code.toString(),
        status_message: message,
        item_url: this.stl_admin_item_url ? this.stl_admin_item_url : "",
      };

      this.isLoading = true;

      ProductServices.updateRefurbishStatus(sendStatus)
        .then((response) => {
          this.isLoading = false;

          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Status Updated!",
                icon: "BoxIcon",
                variant: "success",
              },
            });

            if (sendCommentStatus) {
              this.updateRefurbishComment(sendComment);
            } else {
              this.loadItemDetails();
            }
            this.updateRefDateTime();
            this.getRefurbishComment();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to update status!",
                icon: "BoxIcon",
                variant: "error",
              },
            });
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updateRefurbishComment(sendData) {
      ProductServices.updateRefurbishComment(sendData)
        .then((response) => {
          if (response.data.status) {
            this.updateRefDateTime();
            //this.loadItemDetails();
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updateRefDateTime() {
      const sendPID = { pid: this.product_id };
      ProductServices.updateRefDateTime(sendPID)
        .then((response) => {
          this.loadItemDetails();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getRefurbishComment() {
      let sendData = {
        prod_id: this.product_id,
      };

      ProductServices.getRefurbishComment(sendData)
        .then((response) => {
          if (response.data.status) {
            let commentsData = response.data.data;
            this.getAllRefurbishComments = response.data.data;
            console.log(
              "getAllRefurbishComments: ",
              this.getAllRefurbishComments
            );

            if (commentsData.ref_ur_ship_cost != "") {
              this.ref_admin_ur_ship_cost = commentsData.ref_ur_ship_cost;
            }

            if (commentsData.ref_ur_comment != "") {
              this.ref_admin_ur_comment = commentsData.ref_ur_comment;
            }

            if (commentsData.ref_ur_accept_reject != "") {
              this.ref_admin_ur_ar = commentsData.ref_ur_accept_reject;
            }

            if (commentsData.ref_slr_est_pickup_address != "") {
              //this.ref_slr_est_pickup_address = commentsData.ref_ur_comment;
              this.selectedPickupAddress =
                commentsData.ref_slr_est_pickup_address;
            }

            if (commentsData.ref_slr_est_comment != "") {
              this.ref_slr_est_amount_comment =
                commentsData.ref_slr_est_comment;
            }
            if (commentsData.ref_slr_est_accept_reject != "") {
              this.ref_slr_ur_ar = commentsData.ref_slr_est_accept_reject;
            }

            if (commentsData.ref_admin_auth_ref_price != "") {
              this.ref_admin_auth_ref_price =
                commentsData.ref_admin_auth_ref_price;
            }
            if (commentsData.ref_admin_auth_ship_cost != "") {
              this.ref_admin_auth_ship_cost =
                commentsData.ref_admin_auth_ship_cost;
            }
            if (commentsData.ref_admin_auth_comment != "") {
              this.ref_admin_auth_comment = commentsData.ref_admin_auth_comment;
            }
            if (commentsData.ref_admin_auth_accept_reject != "") {
              this.ref_admin_auth_ar =
                commentsData.ref_admin_auth_accept_reject;
            }
            if (commentsData.ref_admin_auth_pay_amount != "") {
              this.ref_extra_pay_amount =
                commentsData.ref_admin_auth_pay_amount;
            }
            if (commentsData.ref_admin_auth_pay_type != "") {
              this.ref_extra_pay_type = commentsData.ref_admin_auth_pay_type;
            }

            if (commentsData.ref_slr_auth_comment != "") {
              this.ref_slr_auth_comment = commentsData.ref_slr_auth_comment;
            }
            if (commentsData.ref_slr_auth_accept_reject != "") {
              this.ref_slr_auth_ar = commentsData.ref_slr_auth_accept_reject;
            }

            if (commentsData.ref_admin_awb != "") {
              this.ref_admin_awb = commentsData.ref_admin_awb;
            }
            if (commentsData.ref_admin_delivery_date != "") {
              this.ref_admin_delivery_date =
                commentsData.ref_admin_delivery_date;
            }
            if (commentsData.ref_admin_delivery_name != "") {
              this.ref_admin_delivery_name =
                commentsData.ref_admin_delivery_name;
            }
            if (commentsData.ref_admin_shipped != "") {
              this.ref_admin_shipped = commentsData.ref_admin_shipped;
            }

            if (commentsData.ref_status_time != "") {
              this.setRefStatusTime(commentsData.ref_status_time);
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    async getPaymentIntentForProdService() {
      try {
        let prod_id = this.productData.prod_id;
        const response = await PaymentServices.getPaymentIntentForProdService({
          prod_id,
        });
        if (response.data.status) {
          let clientSecret = response.data.data.client_secret;
          this.show_service_payment_form = true;

          const options = {
            clientSecret,
            // Fully customizable with appearance API.
            appearance: {
              /*...*/
            },
          };
          const stripe = window.Stripe(STRIPE_PUB_KEY);
          const elements = stripe.elements(options);
          const paymentElement = elements.create("payment");
          paymentElement.mount("#service-payment-element");

          const form = document.getElementById("service-payment-form");

          form.addEventListener("submit", async (event) => {
            event.preventDefault();
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: window.location.href,
              },
            });
            if (error) {
              const messageContainer = document.querySelector("#error-message");
              messageContainer.textContent = error.message;
            }
          });
        }
      } catch (err) {
        console.log("Error in getting getPaymentIntentForProdService ", err);
      }
    },

    async getPaymentIntentForExtraPay() {
      try {
        let prod_id = this.productData.prod_id;
        console.log(prod_id);
        const response = await PaymentServices.getPaymentIntentForExtraPay({
          prod_id,
        });
        console.log(response);
        if (response.data.status) {
          let clientSecret = response.data.data.client_secret;
          this.show_extra_payment_form = true;
          // const res = await loadScript("https://js.stripe.com/v3/");

          const options = {
            clientSecret,
            // Fully customizable with appearance API.
            appearance: {
              /*...*/
            },
          };
          const stripe = window.Stripe(STRIPE_PUB_KEY);
          const elements = stripe.elements(options);
          const paymentElement = elements.create("payment");
          paymentElement.mount("#extra-payment-element");

          const form = document.getElementById("extra-payment-form");

          form.addEventListener("submit", async (event) => {
            event.preventDefault();
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: window.location.href,
              },
            });
            if (error) {
              const messageContainer = document.querySelector("#error-message");
              messageContainer.textContent = error.message;
            }
          });
        }
      } catch (err) {
        console.log("Error in getting getPaymentIntentForProdService ", err);
      }
    },

    async getPayuHashForProdService() {
      try {
        let prod_id = this.productData.prod_id;
        const response = await PaymentServices.getPayuHashForProdService({
          prod_id,
        });
        if (response.data.status) {
          this.payu_option = {
            ...response.data.data,
          };

          this.$nextTick(() => {
            this.$refs.payuForm.submit();
            this.$refs["payment-processing-modal-ref"].show();
          });
        }
      } catch (err) {
        console.log("Error in getPayuHashForProdService ", err);
      }
    },

    async getPayuHashForExtraPay() {
      try {
        let prod_id = this.productData.prod_id;
        const response = await PaymentServices.getPayuHashForExtraPay({
          prod_id,
        });
        console.log(response.data);
        if (response.data.status) {
          this.payu_option = {
            ...response.data.data,
          };
          this.$nextTick(() => {
            this.$refs.payuForm.submit();
            this.$refs["payment-processing-modal-ref"].show();
          });
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    checkStripePayment() {
      const stripe = window.Stripe(STRIPE_PUB_KEY);

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) return;

      // Retrieve the PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        this.isLoading = true;

        switch (paymentIntent.status) {
          case "succeeded":
            PaymentServices.verifyStripePayment({
              payment_intent: paymentIntent.id,
            })
              .then((response) => {
                if (response.data.status) {
                  this.loadItemDetails();
                  this.isLoading = false;
                  this.$router.push("/product-details?id=" + this.product_id);
                }
              })
              .catch((error) => {
                this.errorMessage = error.message;
                console.error("There was an error in payment response!", error);
              });
            break;

          case "processing":
            this.isLoading = false;
            alert(
              "Payment processing. We'll update you when payment is received."
            );
            break;

          case "requires_payment_method":
            this.isLoading = false;
            alert("Payment failed. Please try another payment method.");
            break;

          default:
            this.isLoading = false;
            message.innerText = "Something went wrong.";
            break;
        }
      });
    },

    paymentSubmit() {
      this.$refs.paymentSubmitButton.click();
    },

    //OTHER FUnctions
    toast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title, //"Failed to update status!",
          icon: icon, //"BoxIcon",
          variant: variant, //"error",
        },
      });
    },

    onCancel() {
      console.log("Seller cancelled the loader.");
    },

    async clickedDownload(file_link) {
      console.log("file_name: ", file_link);

      const fileName = file_link; // 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png';

      try {
        const request = new Request(fileName, {
          method: "POST",
          mode: "cors",
          cache: "default",
          body: JSON.stringify(this.post),
        });

        const response = await fetch(request);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "revivify_receipt.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },

    chooseAddress(index) {
      this.$refs["modal-lg-seller-address-ref"].hide();
      let selectedAddress = this.shippingAddressData[index];
      this.selectedPickupAddress =
        "Name: " +
        selectedAddress.add_name +
        ", Phone: " +
        selectedAddress.add_phone +
        " - " +
        selectedAddress.add_address_line_1 +
        ", " +
        selectedAddress.add_address_line_2 +
        ", " +
        selectedAddress.add_address_line_3 +
        ", Landmark: " +
        selectedAddress.add_landmark +
        ", " +
        selectedAddress.add_city +
        ", " +
        selectedAddress.add_state +
        ", PIN: " +
        selectedAddress.add_pin_code +
        ", " +
        selectedAddress.add_country;

      console.log("selectedAddress: ", this.selectedPickupAddress);

      if (this.address_type == "update_address") {
        this.isLoading = true;
        let sendData = {
          pid: this.productData.prod_id,
          address: this.selectedPickupAddress,
        };
        ProductServices.updateSellAddress(sendData)
          .then((response) => {
            this.isLoading = false;
            if (response.data.status) {
              this.toast("Address updated successfully!", "BoxIcon", "success");
            } else {
              this.toast("Failed to update address", "BoxIcon", "danger");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessage = error.message;
            console.error("There was an error! ", error);
          });
      }
    },

    shareDetails(email, message) {
      let sendData = {
        pid: this.productData.prod_id,
        email: email, //"ajay.chauhan@enpointe.io", //this.share_email,
        message: message != "" ? message : "N/A", //"TEST MESSAGE", //this.share_message,
        reply_email: this.admin_email,
        from_name: this.admin_name,
      };

      this.isLoading = true;

      ProductServices.shareItemDetails(sendData)
        .then((response) => {
          this.isLoading = false;

          this.$refs["ref-item-share-modal"].toggle("#toggle-btn");
          if (response.data.status) {
            let resData = response.data.data;
            this.toast("Shared successfully!", "BoxIcon", "success");
          } else {
            this.toast("Failed to share", "BoxIcon", "danger");
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error! ", error);
        });
    },

    setRefStatusTime(timestamp) {
      console.log("timestamp: ", timestamp);

      if (timestamp.length > 0) {
        let time = timestamp.split(", ");
        let sizeOfArray = time.length ? time.length : 0;
        for (let index = 1; index < 17; index++) {
          if (sizeOfArray >= index) {
            this.rtl_timestamp_array.push(time[index - 1]);
            console.log("timestamp: " + index, time[index - 1]);
          } else {
            this.rtl_timestamp_array.push(null);
          }
        }
      } else {
        for (let index = 0; index < 16; index++) {
          this.rtl_timestamp_array.push(null);
        }
      }
    },

    setSellStatusTime(timestamp) {
      if (timestamp.length > 0) {
        const time = timestamp.split(", ");
        let sizeOfArray = time.length ? time.length : 0;
        for (let index = 1; index < 17; index++) {
          if (sizeOfArray >= index) {
            this.stl_timestamp_array.push(time[index]);
          } else {
            this.stl_timestamp_array.push(null);
          }
        }
      } else {
        for (let index = 0; index < 16; index++) {
          this.stl_timestamp_array.push(null);
        }
      }
    },

    convertCurrency(getCurrency, getAmount) {
      let amount = 0;
      if (getCurrency == "£") {
        amount = Math.round(getAmount).toFixed(0);
      } else if (getCurrency == "$") {
        amount = Math.round(getAmount * 1.23456).toFixed(0);
      } else if (getCurrency == "₹") {
        amount = Math.round(getAmount * 95).toFixed(0);
      }

      return amount;
    },

    timeConverter(UNIX_timestamp) {
      //return moment.unix(1657613556980).format("DD/MM/YYYY HH/mm/ss A");
      /* const milliseconds = UNIX_timestamp;
      const dateObject = new Date(milliseconds);
      const humanDateFormat = dateObject.toLocaleString(); //2019-12-9 10:30:15
      return humanDateFormat; */
      if (UNIX_timestamp != null) {
        const datetime = new Date(parseInt(UNIX_timestamp)).toLocaleString();
        console.log("UNIX_timestamp:", datetime);
        return datetime;
      }
      //return date.toString("MMM dd"); // "Dec 20"
    },

    resetModal() {
      this.siEmail = "";
      this.siMessage = "";
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleItemShareSubmit();
    },

    handleItemShareSubmit() {
      this.$refs.shareItemForm.validate().then((success) => {
        if (success) {
          this.shareDetails(this.siEmail, this.siMessage);
          //console.log("ALL-OK", sendData);
        }
      });
    },

    calculateSellerEarning() {
      //stl_admin_earning_price
      let getValuation = this.stl_admin_ship_cost;
      if (parseInt(getValuation) > 0) {
        let percentage = "0." + this.user_comission;
        let base_per = Math.ceil(eval(getValuation + "*" + percentage));
        this.stl_admin_earning_price = eval(getValuation + "-" + base_per);
      } else {
        this.stl_admin_earning_price = null;
      }
    },

    capitalizeFL(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    titleCase(str) {
      return str
        .split(" ")
        .map(function (val) {
          return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
        })
        .join(" ");
    },

    downloadPDF() {
      let token = localStorage.getItem("token");
      this.isLoading = true;
      //let sendData = { pid: this.productData.prod_id };

      axios({
        url:
          process.env.VUE_APP_BASEURL +
          "/product/downloadPDF/" +
          this.productData.prod_id, //your url
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        console.log("response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "address_sticker.pdf"); //or any other extension
        document.body.appendChild(link);
        this.isLoading = false;
        link.click();
      });
    },

    uploadPickupLabel(event) {
      let URL = process.env.VUE_APP_FILESURL + "/item_pickup_label";

      let data = new FormData();
      event.target.files.forEach((item) => {
        data.append("doc", item);
      });

      let token = localStorage.getItem("token");
      if (token) {
        axios
          .put(URL, data, {
            headers: {
              Authorization: token,
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            let img_arr = response.data.data;
            if (img_arr && img_arr.length > 0) {
              for (let i = 0; i < img_arr.length; i++) {
                const item = img_arr[i];
                console.log(item);
                this.pickup_lable_link = item;
              }
            }
          });
      } else {
        console.log("Auth Token not found ");
      }
    },

    async getTrendList(tag_type_name) {
      try {
        const response = await MasterServices.getTags({ tag_type_name });
        if (response.data.status) {
          this.trend_list = response.data.data;
          //this.selected_trend = this.trend_list.length;
        }
        this.trend_list.push({
          tag_archives: 0,
          tag_cat_percent: "0",
          tag_cat_title: "None",
          tag_created_at: "2022-09-05T12:43:33.723Z",
          tag_created_by: "admin",
          tag_delete: 0,
          tag_description: null,
          tag_id: 0,
          tag_name: "None",
          tag_type: {},
          tag_type_id: 12,
          tag_updated_at: "2022-09-05T12:43:33.723Z",
          tag_updated_by: "admin",
        });
        this.cev.trend = 0;
        console.log("trend_list: ", this.trend_list);
        if (this.productData.prod_trend_value != null) {
          for (let index = 0; index < this.trend_list.length; index++) {
            const element = this.trend_list[index];
            if (element.tag_cat_percent == this.productData.prod_trend_value) {
              this.selected_trend = index;
            }
          }
        } else {
          this.selected_trend = eval(this.trend_list.length + "-" + 1);
        }
      } catch (err) {
        console.log("Error in getting all trend_list ", err);
      }
    },

    async getRarityList(tag_type_name) {
      try {
        const response = await MasterServices.getTags({ tag_type_name });
        if (response.data.status) {
          this.rarity_list = response.data.data;
          //this.selected_rarity = this.rarity_list.length;
          console.log(this.rarity_list);
        }
        this.rarity_list.push({
          tag_archives: 0,
          tag_cat_percent: "0",
          tag_cat_title: "None",
          tag_created_at: "2022-09-05T12:43:33.723Z",
          tag_created_by: "admin",
          tag_delete: 0,
          tag_description: null,
          tag_id: 0,
          tag_name: "None",
          tag_type: {},
          tag_type_id: 12,
          tag_updated_at: "2022-09-05T12:43:33.723Z",
          tag_updated_by: "admin",
        });
        this.cev.rarity = 0;
        console.log("getTags: ", this.rarity_list);
        if (this.productData.prod_rarity_value != null) {
          for (let index = 0; index < this.rarity_list.length; index++) {
            const element = this.rarity_list[index];
            if (element.tag_cat_percent == this.productData.prod_rarity_value) {
              this.selected_rarity = index;
            }
          }
        } else {
          this.selected_rarity = eval(this.rarity_list.length + "-" + 1);
          console.log("length: ", eval(this.rarity_list.length + "-" + 1));
        }
      } catch (err) {
        console.log("Error in getting all rarity_list ", err);
      }
    },

    async getSingleTagDetails(type, tag_name) {
      try {
        const response = await MasterServices.getSingleTagByName({
          tag_name: tag_name,
        });
        console.log(response.data);
        if (response.data.status && type == "designer") {
          this.designer_percentage = response.data.data;
          this.cev.brand = parseInt(response.data.data.tag_cat_percent);
        }
        if (response.data.status && type == "condition") {
          this.condition_percentage = response.data.data;
          console.log("cev: ", this.condition_percentage);
          this.cev.condition = parseInt(response.data.data.tag_cat_percent);
        }
      } catch (err) {
        console.log("Error in getting single tag details ", err);
      }
    },

    updateEstimatevalue() {
      console.log(this.cev);
      if (this.cev.brand == "") {
        this.cev.brand = 0;
      }
      if (this.cev.condition == "") {
        this.cev.condition = 0;
      }
      if (this.cev.age == "") {
        this.cev.age = 0;
      }
      let total = eval(
        this.cev.brand +
          " + " +
          this.cev.age +
          " + " +
          this.cev.condition +
          " + " +
          this.cev.trend +
          " + " +
          this.cev.rarity
      );
      //let calc = eval(parseInt(this.cev.current_value) + " * 0." + total);
      //(100 * partialValue) / totalValue
      // let per = 100 + " * " + total;
      let calc = (total * this.cev.current_value) / 100;
      console.log(calc);
      this.stl_admin_est_amount = Math.ceil(calc) + "";
    },

    updateTrend(index) {
      let val = this.trend_list[index].tag_cat_percent;
      this.cev.trend = val;
      console.log(val);
      this.updateEstimatevalue();
    },
    updateRarity(index) {
      let val = this.rarity_list[index].tag_cat_percent;
      this.cev.rarity = val;
      console.log(val);
      this.updateEstimatevalue();
    },
    setFinalValues() {
      console.log("setFinalValues", this.productData);
      if (this.productData.prod_designer_value != null) {
        this.cev.brand = this.productData.prod_designer_value;
      }
      if (this.productData.prod_age_value != null) {
        this.cev.age = this.productData.prod_age_value;
      }
      if (this.productData.prod_conditions_value != null) {
        this.cev.condition = this.productData.prod_conditions_value;
      }
      if (this.productData.prod_current_vlaue != null) {
        this.cev.current_value = this.productData.prod_current_vlaue;
      }
      if (this.productData.prod_trend_value != null) {
        this.cev.trend = this.productData.prod_trend_value;
      }
      if (this.productData.prod_rarity_value != null) {
        this.cev.rarity = this.productData.prod_rarity_value;
      }
    },
  },
  beforeMount() {
    this.loadUserAction();
    this.loadItemDetails();
    this.checkStripePayment();
    this.getTrendList("Trend");
    this.getRarityList("Rarity");
    this.setFinalValues();

    this.seller_country =
      this.$store.getters["user/getUserDetails"].user_country;

    /* var container = this.$el.querySelector("#endOfChat");
    container.scrollTop = container.scrollHeight; */

    const container = this.$refs.endOfChat;
    container.scrollTop = container.scrollHeight;

  }
};
</script>
<style>
.trend-radio .form-check {
  margin-top: 5px;
  margin-bottom: 10px;
}

#main_slider_img {
  background: #000000;
}

.swiper-slide {
  align-self: center !important;
}

.swiper-slide-duplicate-prev {
  display: none !important;
}

.vs__open-indicator {
  fill: grey !important;
  color: grey !important;
}

[dir="ltr"] .vs__actions > svg {
  fill: grey !important;
}

.draft-div {
  width: 100%;
  background: #00000075;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 2;
}

.draft-div h3 {
  color: white;
  text-align: center;
}

.carousel {
  width: 100%;
}
.details {
  line-height: 45px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5rem;
  /* text-align: center; */
}

.light {
  color: rgba(128, 128, 128, 0.582);
}

/* .hidden {
  display: block !important;
} */

.greyscale {
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  /* filter: url(grayscale.svg); Firefox 4+ */
  filter: gray; /* IE 6-9 */
}

.avatar {
  height: 10rem;
  width: 10rem;
}

.text-dark {
  color: #000000;
  margin-bottom: 0px;
}

.lh-30 {
  line-height: 30px !important;
}
.timeline-variant-success .timeline-item-point {
  background-color: #6f7f77 !important;
}

.size-chart {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #3d4e46;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #ff8100;
  cursor: pointer;
  border-radius: 4px;
}

[dir]
  .custom-control-primary
  .custom-control-input:checked
  ~ .custom-control-label::before,
[dir]
  .custom-control-primary
  .custom-control-input:active
  ~ .custom-control-label::before {
  border-color: #697971 !important;
  background-color: #697971 !important;
}

.modal.dialog1 {
  /* customized styles. this way you can have N dialogs, each one with its own size styles */
  width: 60%;
  height: 50%;
  left: 20%; /* ( window's width [100%] - dialog's width [60%] ) / 2 */
}

/* media query for mobile devices */
@media (max-width: 480px) {
  .modal.dialog1 {
    height: 90%;
    left: 5%; /* ( window's width [100%] - dialog's width [90%] ) / 2 */
    top: 5%;
    width: 90%;
  }
}

/* split the modal in two divs (header and body) with defined heights */
.modal .modal-header {
  height: 10%;
}

.modal .modal-body {
  height: 60%;
}

/* The div inside modal-body is the key; there's where we put the content (which may need the vertical scrollbar) */
/* .modal .modal-body div {
  height: 60%;
  overflow-y: auto;
} */

.swiper-button-prev,
.swiper-button-next {
  padding: 10px;
  margin: 5px;
  background-color: #0000003a;
}

.share_social_icon {
  display: block;
  margin: 1rem 0;
  font-size: 20px;
  background-color: #ab6c3d !important;
  border-color: #ab6c3d !important;
  width: 100%;
}
.download_icon span svg {
  width: 15px !important;
  height: 15px !important;
}
.demo-inline-spacing {
  display: block;
}
.timeline_revive label {
  font-size: 1rem !important;
}
.timeline_revive .custom-control-label {
  font-size: 0.875rem !important;
}
.timeline_revive .custom-control-label::after {
  width: 13px;
  height: 13px;
}
.timeline_revive .custom-control-label::before {
  width: 13px;
  height: 13px;
}
.timeline_revive .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3a4b43;
  background-color: #3a4b43;
}
.timeline_revive .demo-inline-spacing .custom-control {
  margin-right: 0;
  margin-top: 0.5rem;
}
/* .trend-radio .custom-radio{
  width: 160px;
}  */




:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 1px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}


.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  max-height: 300px !important;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

/* .msg:last-of-type {
  margin: 0;
} */

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}


</style>